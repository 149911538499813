import React from 'react'
import { useTheme, css, StyleObjectOrFn } from '@chakra-ui/react'
import { Global as EmotionGlobal } from '@emotion/react'

export interface GlobalProps {
  styles: StyleObjectOrFn
}

export const Global: React.FC<React.PropsWithChildren<GlobalProps>> = ({ styles }) => {
  const theme = useTheme()
  return <EmotionGlobal styles={css(styles)(theme)} />
}
