import React from 'react'
import Script from 'next/script'

export { default as GoogleTagManagerData } from './data'

export interface GoogleTagManagerProps {
  containerId?: string
  auth?: string
  preview?: string
}

export default function GoogleTagManager({
  containerId,
  ...props
}: GoogleTagManagerProps): JSX.Element | null {
  if (!containerId) {
    return null
  }

  return <GoogleTagManagerInner containerId={containerId} {...props} />
}

interface GoogleTagManagerInnerProps {
  containerId: string
  auth?: string
  preview?: string
}

function GoogleTagManagerInner({
  containerId,
  auth,
  preview,
}: GoogleTagManagerInnerProps): JSX.Element {
  const gtmBaseUrl = 'https://www.googletagmanager.com'
  let gtmScriptSrc = `${gtmBaseUrl}/gtm.js?id=${containerId}`
  if (auth) {
    gtmScriptSrc += `&gtm_auth=${auth}`
  }
  if (preview) {
    gtmScriptSrc += `&gtm_preview=${preview}`
  }
  gtmScriptSrc += `&gtm_cookies_win=x`
  const gtmNoScriptSrc = `${gtmBaseUrl}/ns.html?id=${containerId}`
  return (
    <>
      {/* Based on testing I suspect the lint rule is wrong and beforeInteractive works outside document */
      /* eslint-disable-next-line @next/next/no-before-interactive-script-outside-document */}
      <Script id="google-tag-manager-event-gtm-js" strategy="beforeInteractive">
        {`window.dataLayer = window.dataLayer || [{'gtm.start': new Date().getTime(), event:'gtm.js'}];`}
      </Script>
      <Script
        id="google-tag-manager"
        strategy="afterInteractive"
        src={gtmScriptSrc}
      />
      <noscript>
        <iframe
          title="Google Tag Manager"
          src={gtmNoScriptSrc}
          height="0"
          width="0"
          style={{ display: 'none', visibility: 'hidden' }}
        />
      </noscript>
    </>
  )
}
