/**
 * Returns a boolean value based on the input value.
 * @param {string | boolean | undefined} value - The value to be evaluated.
 * @returns {boolean} - The boolean value based on the input value.
 */
function getEnvBool(value) {
  if (!value) {
    return false
  }

  return ['true', true].includes(value)
}

module.exports = { getEnvBool }
