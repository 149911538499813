import {
  InMemoryCache,
  NormalizedCacheObject,
  ApolloClient,
  ApolloLink,
} from '@apollo/client'
import type { Auth0ContextInterface } from '@auth0/auth0-react'
import Cookies from 'universal-cookie'
import { isDefinedAndNotNull } from '../lib'
import {
  makeAuthenticationTokenLink,
  makeAuthenticationResetLink,
  makeAuthorizationLink,
} from './links/auth'
import { makeDefaultHeadersLink } from './links/default-headers'
import { makeHttpLink } from './links/http'
import { makeOperationLoggingLink } from './links/logging'
import { makeRetryLink } from './links/retry'
import typePolicies from './type-policies'

interface MakeApolloClientOptions {
  client: {
    name: string
    version?: string | undefined
  }
  operationLogging: boolean
  defaultVersion: number
  urlsByVersion: Record<number, string>
  requestId?: string
  auth0?: Pick<
    Auth0ContextInterface,
    'getAccessTokenSilently' | 'getIdTokenClaims'
  > | null
  cookies?: Cookies
  getSessionId?: () => string
  getAnonymousId?: () => string | null | undefined
}

export function makeApolloClient({
  client,
  operationLogging,
  defaultVersion,
  urlsByVersion,
  requestId,
  auth0,
  cookies,
  getSessionId,
  getAnonymousId,
}: MakeApolloClientOptions): ApolloClient<NormalizedCacheObject> {
  const link = ApolloLink.from(
    [
      operationLogging ? makeOperationLoggingLink() : null,
      makeDefaultHeadersLink({ requestId, getSessionId, getAnonymousId }),
      makeAuthenticationTokenLink({ auth0, cookies }),
      makeAuthenticationResetLink({ auth0, cookies }),
      makeAuthorizationLink(),
      makeRetryLink(),
      makeHttpLink({ urlsByVersion, defaultVersion }),
    ].filter(isDefinedAndNotNull)
  )

  const cache = new InMemoryCache({
    typePolicies,
  })

  return new ApolloClient({
    ssrMode: typeof window === 'undefined',
    link,
    cache,
    name: client.name,
    version: client.version,
    defaultOptions: {
      query: { errorPolicy: 'all' },
      watchQuery: { errorPolicy: 'all' },
      mutate: { errorPolicy: 'all' },
    },
  })
}
