import { useCallback } from 'react'
import { LogoutOptions, useAuth0, OAuthError } from '@auth0/auth0-react'
import * as Sentry from '@sentry/nextjs'
import { useCookies, Cookies } from 'react-cookie'

import { RawCookieName } from '@scentregroup/shared/constants'
import { decodeIdToken } from '@scentregroup/shared/helpers/id-token'
import useSessionLogger, {
  type SessionLogger,
} from '@scentregroup/shared/hooks/use-session-logger'

type UseSessionResponse = {
  setSession: (idToken: string) => void
  logout: (options?: LogoutOptions | undefined) => void
  clearSession: () => void
  renewSession: () => Promise<void>
}

export type SetCookie = InstanceType<typeof Cookies>['set']

// eslint-disable-next-line @typescript-eslint/no-empty-function
const noop = (): void => {}

export function setSession(
  setCookie: SetCookie,
  idToken: string,
  sessionLogger: SessionLogger = noop
): void {
  try {
    const { scentregroupId } = decodeIdToken(idToken)

    // We are explictly overriding options here because we don't
    // know how setCookie was instantiated and we want these cookies
    // to be consistent.
    const cookieOptions = {
      path: '/',
      sameSite: 'strict' as const,
    }

    setCookie(RawCookieName.ID_TOKEN, idToken, cookieOptions)
    sessionLogger('setSession', RawCookieName.ID_TOKEN, idToken, cookieOptions)

    if (scentregroupId) {
      setCookie(RawCookieName.SCENTREGROUP_ID, scentregroupId, cookieOptions)
      sessionLogger(
        'setSession',
        RawCookieName.SCENTREGROUP_ID,
        scentregroupId,
        cookieOptions
      )
    }
  } catch (e: unknown) {
    Sentry.captureException(e)
    console.error(e)
  }
}

const useSession = (): UseSessionResponse => {
  const sessionLogger = useSessionLogger()
  const [_, setCookie, removeCookie] = useCookies([
    RawCookieName.ID_TOKEN,
    RawCookieName.SCENTREGROUP_ID,
    RawCookieName.CART_ID,
    RawCookieName.CART_PREVIOUS_ID,
    RawCookieName.CART_COUNT,
  ])

  const {
    logout: auth0Logout,
    getAccessTokenSilently,
    getIdTokenClaims,
  } = useAuth0()

  const clearSession = useCallback(() => {
    const options = { path: '/' }

    const removeCookieLog = (name: string): void => {
      sessionLogger('clearSession', 'removeCookie', name, options)
    }

    // General
    removeCookie(RawCookieName.ID_TOKEN, options)
    removeCookieLog(RawCookieName.ID_TOKEN)
    removeCookie(RawCookieName.SCENTREGROUP_ID, options)
    removeCookieLog(RawCookieName.SCENTREGROUP_ID)

    // Westfield online related
    removeCookie(RawCookieName.CART_ID, options)
    removeCookieLog(RawCookieName.CART_ID)
    removeCookie(RawCookieName.CART_PREVIOUS_ID, options)
    removeCookieLog(RawCookieName.CART_PREVIOUS_ID)
    removeCookie(RawCookieName.CART_COUNT, options)
    removeCookieLog(RawCookieName.CART_COUNT)
  }, [removeCookie, sessionLogger])

  const logout = useCallback(
    async (options?: LogoutOptions | undefined) => {
      clearSession()
      auth0Logout(options)
      sessionLogger('logout', options)
    },
    [clearSession, auth0Logout, sessionLogger]
  )

  const renewSession = useCallback(async () => {
    try {
      // As the purpose of this method is to renew the session, we are ignoring
      // the cache, otherwise this may return the current session.
      sessionLogger('renewSession starting')
      await getAccessTokenSilently({ cacheMode: 'off' })

      const idTokenClaim = await getIdTokenClaims()
      if (idTokenClaim?.__raw) {
        sessionLogger('renewSession', idTokenClaim)
        setSession(setCookie, idTokenClaim.__raw)
      } else {
        throw new Error('Failed to renew session')
      }
    } catch (e: unknown) {
      sessionLogger('renewSession', 'error', e)
      if (e instanceof OAuthError) {
        if (e.error === 'login_required') {
          logout()
          sessionLogger('renewSession', 'logging out')
        }
      } else {
        Sentry.captureException(e)
        console.error(e)
      }
    }
  }, [
    getAccessTokenSilently,
    logout,
    setCookie,
    sessionLogger,
    getIdTokenClaims,
  ])

  return {
    clearSession,
    logout,
    renewSession,
    setSession: useCallback(
      (idToken: string) => {
        setSession(setCookie, idToken, sessionLogger)
      },
      [setCookie, sessionLogger]
    ),
  }
}

export default useSession
