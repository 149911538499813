import { useContext, useEffect, useState } from 'react'
import { getClientConfigBool } from '../config'
import { FeatureFlagsContext } from '../context'
import { FeatureFlagName } from '../feature-flags'

let isInitialMount = true

function useFeatureFlag(featureFlagName: FeatureFlagName): boolean {
  const featureFlags = useContext(FeatureFlagsContext)
  const [isMounted, setIsMounted] = useState(() => !isInitialMount)

  useEffect(() => {
    setIsMounted(true)
    isInitialMount = false
  }, [])
  // This is a hack but until we can deal with hydration issues
  // better generally we probably need such hacks:
  const isTest = process.env.NODE_ENV === 'test'
  if (isMounted || isTest) {
    return featureFlags[featureFlagName]
  } else {
    // prevent 'Hydration failed because the initial UI does not match what was rendered on the server'
    return getClientConfigBool(featureFlagName)
  }
}

export default useFeatureFlag
