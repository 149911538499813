import { ApolloLink } from '@apollo/client'
import { ErrorLink } from '@apollo/client/link/error'

export function makeOperationLoggingLink(): ApolloLink {
  return ApolloLink.from([
    (operation, forward) => {
      console.log(`GraphQL Operation: ${operation.operationName}`)

      return forward(operation)
    },
    new ErrorLink(({ operation, graphQLErrors, networkError }) => {
      if (graphQLErrors) {
        for (const error of graphQLErrors) {
          console.error('GraphQL Error:', {
            operationName: operation.operationName,
            message: error.message,
            variables: operation.variables,
          })
        }
      }
      if (networkError) {
        console.error('Network Error:', {
          operationName: operation.operationName,
          message: networkError.message,
          variables: operation.variables,
        })
      }
    }),
  ])
}
