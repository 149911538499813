import React, { useContext, createContext, FC, PropsWithChildren } from 'react'

export const countries = ['Australia', 'New Zealand'] as const

export type Country = (typeof countries)[number]

const CountryContext = createContext<Country | null>(null)

export const CountryProvider: FC<PropsWithChildren<{ country: Country }>> = ({
  country,
  children,
}) => (
  <CountryContext.Provider value={country}>{children}</CountryContext.Provider>
)

export const useCountry = (): Country => {
  const country = useContext(CountryContext)
  if (country === null) {
    throw new Error('useCountry must be used within a <CountryProvider />')
  }
  return country
}
