import React from 'react'
import {
  AnalyticsProviderSimplified as AnalyticsProvider,
  useConsoleAnalyticsHandler,
  useDatalayerAnalyticsHandler,
  AnalyticsHandlerRegistrantSimplified as AnalyticsHandlerRegistrant,
  useDatalayerGa4AnalyticsHandler,
  useProfileCookieHandler,
  NextJsPageViewTrackerSimplified as NextJsPageViewTracker,
} from '@scentregroup/analytics'
import { FeatureFlagName } from '../feature-flags'
import useFeatureFlag from '../hooks/use-feature-flag'
import { AnalyticsUserIdTracker } from '../analytics-helpers'
import { SentryAnalyticsHandler } from '../analytics-sentry'
import { transformObjectActionEvent } from '../analytics-datalayer/event-transformation'
import {
  LegacyEventProperties,
  ObjectActionEventProperties,
  Profile,
} from './types'

const RegisterHandlers: React.FC = (): JSX.Element => {
  const enableAnalyticsConsole = useFeatureFlag(
    FeatureFlagName.NEXT_PUBLIC_FEAT_FLAG_ANALYTICS_CONSOLE
  )
  const datalayerAnalyticsHandler = useDatalayerAnalyticsHandler<
    ObjectActionEventProperties,
    LegacyEventProperties,
    Profile
  >({
    transformObjectActionEvent,
  })
  const consoleAnalyticsHandler = useConsoleAnalyticsHandler()
  // TODO If we start tracking non-page-views via the GA4 handler
  // and we need to convert legacy events, we will have to refactor
  // '../analytics-segment/event-transformation' to first transform
  // legacy events to object action events and pass that transform
  // in here.
  const datalayerGa4AnalyticsHandler = useDatalayerGa4AnalyticsHandler()
  const profileCookieHandler = useProfileCookieHandler('_sg_analytics_profile')
  return (
    <>
      <AnalyticsHandlerRegistrant {...datalayerAnalyticsHandler} />
      {enableAnalyticsConsole && (
        <AnalyticsHandlerRegistrant {...consoleAnalyticsHandler} />
      )}
      <AnalyticsHandlerRegistrant {...datalayerGa4AnalyticsHandler} />
      <AnalyticsHandlerRegistrant {...profileCookieHandler} />
      <SentryAnalyticsHandler />
    </>
  )
}

const AnalyticsProvidersAndInit: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  return (
    <AnalyticsProvider>
      <RegisterHandlers />
      <NextJsPageViewTracker />
      <AnalyticsUserIdTracker />
      {children}
    </AnalyticsProvider>
  )
}

export default AnalyticsProvidersAndInit
