import React from 'react'
import {
  chakra,
  extendTheme,
  ChakraProvider,
  ResponsiveValue,
  SystemProps,
  ThemeConfig,
} from '@chakra-ui/react'
import { Global as EmotionGlobal } from '@emotion/react'
import { ChakraTheme } from '@chakra-ui/theme'

import { theme as defaultTheme } from './theme'
import { styles } from './styles'
import { Link, HubLinkContext } from './link-context'

interface ProviderProps {
  theme?: ChakraTheme
  resetCss?: boolean
  Link?: Link
}

// Default to 'sm' (mobile first) as a guess for SSR/SSG
export const DEFAULT_BREAKPOINT = 'sm'
const extensions: {
  components: Record<string, Record<string, unknown>>
  styles: Record<string, Record<string, unknown>>
  config: ThemeConfig
} = {
  components: {},
  styles,
  config: {
    initialColorMode: 'light',
    useSystemColorMode: false,
  },
}
export const injectTheme = (
  componentName: string,
  extension: Record<string, unknown>
): void => {
  if (extensions.components[componentName]) {
    console.trace(
      `Tried to extend Hub theme with component that has already had it's them injected: ${componentName}`
    )
  } else {
    extensions.components[componentName] = extension
  }
}

export const hub = chakra

export { HubLinkContext }

// TODO: Italic & BoldItalic font faces
export const HubProvider: React.FC<React.PropsWithChildren<ProviderProps>> = ({
  children,
  theme,
  resetCss = true,
  Link,
}) => {
  const extendedTheme = extendTheme(extensions, theme ?? (defaultTheme as any))
  return (
    <HubLinkContext.Provider value={Link}>
      <ChakraProvider resetCSS={resetCss} theme={extendedTheme}>
        {
          // Preferencing the usage of a class to override the inline 'color-scheme' style provided by Chakra so as to
          // be more explicit about the intentions.
          // The issue with the inline Chakra 'color-scheme' is that is only specifies 'light', and not
          // 'only light', so the browser's automatic dark mode (if turned on) always takes precedence.
          // TODO: Remove if Chakra adds support to force a mode using the 'only' prefix, or if an option to disable
          //       the setting of the inline color-scheme style is provided. In the case of the latter, the meta
          //       tag should take care of forcing light mode if it can be disabled.
        }
        <EmotionGlobal
          styles={`
          .force-color-scheme-light {
            color-scheme: only light !important;
          }
        `}
        />
        {/* NOTE: These global styles must be inside the chakra provider so they
      can override the global CSS Chakra injects when selectors have the same
      specificity */}
        <EmotionGlobal
          styles={`
          @font-face {
            font-family: 'Cera PRO';
            src: url('https://images.scentregroup.io/raw/upload/v1/fonts/cera-pro-regular-optimised.woff2') format('woff2'), url('https://images.scentregroup.io/raw/upload/v1659999858/fonts/CeraPRO-Regular.woff') format('woff');
            font-weight: normal;
            font-style: normal;
          }
    
          @font-face {
            font-family: 'Cera PRO';
            src: url('https://images.scentregroup.io/raw/upload/v1/fonts/cera-pro-medium-optimised.woff2') format('woff2'), url('https://images.scentregroup.io/raw/upload/v1659999857/fonts/cera-pro-medium.woff') format('woff');
            font-weight: bold;
            font-style: normal;
          }

          @font-face {
            font-family: 'Cera PRO';
            src: url('https://images.scentregroup.io/raw/upload/v1/fonts/cera-pro-medium-optimised.woff2') format('woff2'), url('https://images.scentregroup.io/raw/upload/v1659999857/fonts/cera-pro-medium.woff') format('woff');
            font-weight: 500;
            font-style: normal;
          }
        `}
        />
        {/* Global style for Braze In-app Messages */}
        <EmotionGlobal
          styles={`
          .ab-iam-root.v3 .ab-in-app-message.ab-slideup .ab-message-text {
            font-family: 'Cera PRO' !important;
          }
        `}
        />
        {resetCss && (
          <EmotionGlobal
            styles={`
          ${
            '' /*
            Opinionated:
            Reset list styling to provide a more consistent and
            expected behaviour for developers.

            Why:
            Browsers default to `list-style-position: outside` on ul & ol,
            which means the bullets sit _outside_ the container of the ul. This is
            regardless of box-sizing properties that may be set. Because of this,
            the bullets will fall off the left-side of the container. To counter
            that side-effect, browsers default to inserting left padding on ul &
            ol such that the bullets appear to be back within the container again.

            To make this a bit more predictable and force the developer to think
            about where to place the bullets, we reset the style completely here.

            I (Jess) found it interesting that Chakra does not do any list style
            resets (https://git.io/J3R0R).

            These particluar styles are derived from other popular libraries which
            do a reset on list styles:

            - TailwindCSS: https://git.io/J3R0P
            - Bootstrap: https://getbootstrap.com/docs/5.0/content/reboot/#lists
            - Sanitize.css: https://git.io/J3R0b

            A note on list-style-position:
            Ideally we could use `list-style-position: inside` which would put the
            bullets back inside the container. Unfortuantely, Firefox & Safari
            have a long running issue where a block-level child will then be put
            on a new line (beacuse it incorrectly treats the bullet as a block):
            https://bugzilla.mozilla.org/show_bug.cgi?id=36854
          */
          }
          ol,
          ul {
            list-style: none;
            padding: 0;
          }
        `}
          />
        )}
        {children}
      </ChakraProvider>
    </HubLinkContext.Provider>
  )
}

type ChakraResponsiveObject<T = string> = Partial<Record<string, T>>

export {
  colorPalette,
  sizeScale,
  typographyScale,
  iconSizes,
  lineHeights,
} from './theme'

export const colors = defaultTheme.colors
export const fonts = defaultTheme.fonts
export const fontSizes = defaultTheme.fontSizes
export const space = defaultTheme.space
export const sizes = defaultTheme.sizes

export {
  useToken,
  useTheme,
  useBreakpoint,
  css,
  forwardRef,
  keyframes,
} from '@chakra-ui/react'
export { Fade, ScaleFade, SlideFade, Slide, Collapse } from '@chakra-ui/react'
export { isDark, isLight, darken, lighten } from '@chakra-ui/theme-tools'
export { Global } from './global'
export type { As, SystemStyleObject as HubStyleObject } from '@chakra-ui/react'
export * from './utils'
export type StandardSpacings = keyof typeof defaultTheme.space | string
export type StandardSizes = keyof typeof defaultTheme.sizes | string
export type StandardIconSizes = keyof typeof defaultTheme.iconSizes
export type StandardFontSizes = keyof typeof defaultTheme.fontSizes
export type StandardLineHeights = keyof typeof defaultTheme.lineHeights
export type StandardColors = keyof typeof defaultTheme.colors
export type StandardTextAlignment = SystemProps['textAlign']
export type HubResponsiveValue<T> = ResponsiveValue<T>
export type IconColors = Extract<
  StandardColors,
  | 'iconPrimary'
  | 'iconSecondary'
  | 'iconSurfacePrimary'
  | 'iconTertiary'
  | 'iconSuccess'
  | 'iconInfo'
  | 'iconWarningiconError'
  | 'iconError'
>

/**
 * Allow single value, or array of single values, but never object syntax.
 * Why? Our utilities which augment Chakra features can't handle the object
 * syntax. TODO: FIXME so that we _can_ handle the object syntax.
 */
export type HubResponsiveArray<T> = Exclude<
  HubResponsiveValue<T>,
  ChakraResponsiveObject<T>
>

/**
 * Allows the extraction of the non-object style responsive values by inferring
 * the type T from the provided value U
 * Can be used like rowSpan: HubExcludeResponsiveObject<GridItemProps['rowSpan']>
 * This will return a responsive value without the object options
 * We know we want to exclude all functions because the inferring causes primitives
 * and literals to add several functions to the type union
 */
export type HubExcludeResponsiveObject<U> = U extends HubResponsiveValue<
  infer T
>
  ? Exclude<
      // eslint-disable-next-line @typescript-eslint/ban-types
      HubResponsiveValue<Exclude<T, Function | never | undefined>>,
      // eslint-disable-next-line @typescript-eslint/ban-types
      ChakraResponsiveObject<T>
    >
  : never
