import React from 'react'
import ClientOnly from '../../components/client-only'
import { useLocalUser } from '../../hooks/use-local-user'

export default function GoogleTagManagerData(): JSX.Element {
  const attributes: Record<string, string> = {}

  const user = useLocalUser()
  if (user) {
    attributes['data-scentregroup-id'] = user.scentregroupId
  }

  return (
    <ClientOnly>
      <div id="sg-google-tag-manager-data" {...attributes} />
    </ClientOnly>
  )
}
