import React from 'react'
import style from './index.module.scss'

interface Props {
  skipToId: string
  text?: string
}

const SkipToContentLink = ({
  skipToId,
  text = 'Skip to content',
  ...props
}: Props): JSX.Element => (
  <a {...props} href={`#${skipToId}`} className={style.link}>
    {text}
  </a>
)

export default SkipToContentLink
