import { ParsedUrlQuery, stringify } from 'querystring'
import React, { ReactElement, useContext, useMemo, useEffect } from 'react'
import mapValues from 'lodash/mapValues'
import { last } from 'lodash'
import { FeatureFlagsContext } from '../context'
import { FeatureFlagName, FeatureFlags } from '../feature-flags'
import { getClientConfigBool } from '../config'
import { RawCookieName } from '../constants'
import { isBrowser, isLocalStorageEnabled } from '../env'
import { useRemoteFeatureManagement } from '../hooks/use-remote-feature-management'

const flagValuesFromEnv = mapValues(FeatureFlagName, getClientConfigBool)

interface FfProps {
  children: ReactElement
  doGetFromEnv?: boolean
  overrides?: Partial<FeatureFlags>
}

export default function FeatureFlagsProvider({
  children,
  doGetFromEnv,
  overrides,
}: FfProps): ReactElement {
  const remoteFeatures = useRemoteFeatureManagement()

  const higherContext = useContext(FeatureFlagsContext)
  const baseValues = doGetFromEnv ? flagValuesFromEnv : higherContext

  return (
    <FeatureFlagsContext.Provider
      value={{
        ...baseValues,
        ...remoteFeatures?.flags,
        ...overrides,
        remote: remoteFeatures,
      }}
    >
      {children}
    </FeatureFlagsContext.Provider>
  )
}

const { FEATURE_FLAGS } = RawCookieName

export function useFeatureFlagOverridesFromQuery(
  queryParams: ParsedUrlQuery
): Partial<FeatureFlags> {
  const storedFlags =
    isBrowser() && isLocalStorageEnabled()
      ? localStorage.getItem(FEATURE_FLAGS) ?? ''
      : ''

  const overrides = useMemo(() => {
    const normalizedQueryParams = new URLSearchParams(stringify(queryParams))
    const storedParams = new URLSearchParams(storedFlags)

    const allParams = new URLSearchParams()

    for (const [key, value] of storedParams) {
      allParams.set(key, value)
    }

    for (const [key, value] of normalizedQueryParams) {
      allParams.set(key, value)
    }

    return determineFlags(FeatureFlagName, allParams)
  }, [storedFlags, queryParams])

  useEffect(() => {
    if (!isLocalStorageEnabled()) {
      return
    }

    const updatedStoredParams = Object.entries(overrides).reduce(
      (acc, current) => {
        acc.set(
          flagNameToQueryParamName(current[0]),
          current[1] ? 'enabled' : 'disabled'
        )
        return acc
      },
      new URLSearchParams()
    )

    const updatedStoredFlags = updatedStoredParams.toString()

    if (!updatedStoredFlags) {
      localStorage.removeItem(FEATURE_FLAGS)
    } else if (storedFlags !== updatedStoredFlags) {
      localStorage.setItem(FEATURE_FLAGS, updatedStoredFlags)
    }
  }, [storedFlags, overrides])

  return overrides
}

export function determineFlags(
  flagNames: Record<string, string>,
  queryParams: URLSearchParams
): Partial<FeatureFlags> {
  if (flagNames) {
    const overrides: Partial<FeatureFlags> = {}

    for (const flagName in flagNames) {
      const queryStringFlagName = flagNameToQueryParamName(flagName)

      const finalValue = last(queryParams.getAll(queryStringFlagName))

      if (finalValue === 'enabled') {
        overrides[flagName as FeatureFlagName] = true
      } else if (finalValue === 'disabled') {
        overrides[flagName as FeatureFlagName] = false
      }
    }

    return overrides
  } else {
    return {}
  }
}

function flagNameToQueryParamName(flagName: string): string {
  return flagName
    .replace('NEXT_PUBLIC_FEAT_FLAG_', '')
    .toLowerCase()
    .replace(/_/g, '-')
}
