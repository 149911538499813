import React, { useEffect, useState } from 'react'

const ClientOnly: React.FC<React.PropsWithChildren<unknown>> =
  function ClientOnly({ children }) {
    const [hasBeenMounted, setHasBeenMounted] = useState(false)

    useEffect(() => {
      setHasBeenMounted(true)
    }, [])

    if (!hasBeenMounted) {
      return <></>
    }

    return <>{children}</>
  }

export default ClientOnly

export function withClientOnly(
  Component: React.ComponentType<React.PropsWithChildren<unknown>>
): React.ComponentType<React.PropsWithChildren<unknown>> {
  const ClientOnlyWrapped: React.FC<React.PropsWithChildren<unknown>> = () => (
    <ClientOnly>
      <Component />
    </ClientOnly>
  )

  const wrappedName =
    Component.displayName ?? Component.name ?? React.Component.name
  ClientOnlyWrapped.displayName = `WithClientOnly(${wrappedName})`

  return ClientOnlyWrapped
}
