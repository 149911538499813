import { useMemo } from 'react'
import { useCookies } from 'react-cookie'
import { RawCookieName } from '../constants'
import { decodeIdToken } from '../helpers/id-token'

export interface LocalUser {
  isLoggedIn: boolean
  scentregroupId: string
  email?: string
  firstName?: string
  lastName?: string
  emailVerified?: boolean
  emailPending?: string
}

function getLocalUserFromIdToken(idToken: string): LocalUser | undefined {
  if (!idToken) {
    return undefined
  }

  try {
    const {
      scentregroupId,
      email,
      firstName,
      lastName,
      emailVerified,
      emailPending,
    } = decodeIdToken(idToken)

    return scentregroupId
      ? {
          isLoggedIn: Boolean(scentregroupId),
          scentregroupId,
          email,
          firstName,
          lastName,
          emailVerified,
          emailPending,
        }
      : undefined
  } catch {
    return undefined
  }
}

export function getLocalUserFromRequestCookies(
  cookies: Partial<{ [key: string]: string }>
): LocalUser | undefined {
  const idTokenCookieValue = cookies[RawCookieName.ID_TOKEN]
  return idTokenCookieValue
    ? getLocalUserFromIdToken(idTokenCookieValue)
    : undefined
}

export function useLocalUser(): LocalUser | undefined {
  const [cookies] = useCookies([RawCookieName.ID_TOKEN])
  const idTokenCookieValue = cookies[RawCookieName.ID_TOKEN]
  return useMemo(
    (): LocalUser | undefined => getLocalUserFromIdToken(idTokenCookieValue),
    [idTokenCookieValue]
  )
}
