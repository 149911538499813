import { useContext } from 'react'
import { SessionIdContext } from './context'

export type UseSessionIdResult = {
  getOrGenerateSessionId(): string
  regenerateSessionId(): string
}

export const useSessionId = (): UseSessionIdResult => {
  const sessionIdProvider = useContext(SessionIdContext)
  if (!sessionIdProvider) {
    throw new Error('SessionIdContext is not defined')
  }

  const { generateSessionId, getOrGenerateSessionId } = sessionIdProvider

  return {
    getOrGenerateSessionId,
    regenerateSessionId() {
      return generateSessionId()
    },
  }
}
