import React from 'react'
import { Spinner as ChakraSpinner } from '@chakra-ui/react'
import {
  As,
  HubStyleObject,
  forwardRef,
  injectTheme,
} from '@hub/design-system-base'
import { spinnerTheme } from './theme'

injectTheme('Spinner', spinnerTheme)

type HubSpinnerVariants = keyof typeof spinnerTheme.variants

export interface SpinnerProps {
  sx?: HubStyleObject
  as?: As
  color?: React.ComponentProps<typeof ChakraSpinner>['color']
  emptyColor?: React.ComponentProps<typeof ChakraSpinner>['emptyColor']
  thickness?: React.ComponentProps<typeof ChakraSpinner>['thickness']
  speed?: React.ComponentProps<typeof ChakraSpinner>['speed']
  variant?: HubSpinnerVariants
  isSeasonal?: boolean
}

type VariantDefaultPropsType = {
  color?: SpinnerProps['color']
  thickness?: SpinnerProps['thickness']
  speed?: SpinnerProps['speed']
  emptyColor?: SpinnerProps['emptyColor']
}

const variantDefaultProps: Record<HubSpinnerVariants, VariantDefaultPropsType> =
  {
    default: {
      color: 'quartz',
      thickness: '2px',
      speed: '1s',
    },
    large: {
      color: 'brand',
      thickness: '6px',
      speed: '0.6s',
      emptyColor: 'smoke',
    },
  }

type DefaultPropValueType =
  VariantDefaultPropsType[keyof VariantDefaultPropsType]

const getValueOrDefault = (
  variant: HubSpinnerVariants,
  key: keyof VariantDefaultPropsType,
  override: DefaultPropValueType
): DefaultPropValueType | undefined =>
  override ?? variantDefaultProps[variant][key]

export const Spinner = forwardRef<SpinnerProps, typeof ChakraSpinner>(
  (
    {
      sx,
      as,
      children,
      color,
      emptyColor,
      thickness,
      speed,
      variant = 'default',
      isSeasonal = false,
      ...props
    },
    ref
  ) => {
    const overrideColor = color || (isSeasonal && 'seasonal') || undefined
    return (
      <ChakraSpinner
        ref={ref}
        as={as}
        sx={sx}
        color={getValueOrDefault(variant, 'color', overrideColor)}
        emptyColor={getValueOrDefault(variant, 'emptyColor', emptyColor)}
        thickness={getValueOrDefault(variant, 'thickness', thickness)}
        speed={getValueOrDefault(variant, 'speed', speed)}
        variant={variant}
        {...props}
      >
        {children}
      </ChakraSpinner>
    )
  }
)

Spinner.displayName = 'Spinner'
