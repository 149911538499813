const standardFocus = {
  outline: 'revert',
  // important is required as chakra box shadow is added via [data-focus]
  boxShadow: 'none !important',
}

export const styles = {
  global: {
    a: {
      _focus: standardFocus,
    },
    button: {
      _focus: standardFocus,
    },
    input: {
      _focus: standardFocus,
    },
    textarea: {
      _focus: standardFocus,
    },
  },
}
