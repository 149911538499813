import Cookies from 'universal-cookie'
import { SessionIdStorage } from '.'

const SECONDS_IN_DAY = 86400

export class CookieSessionIdStorage implements SessionIdStorage {
  constructor({
    cookieManager = new Cookies(),
    cookieName,
    expiryInDays,
  }: {
    cookieManager?: Cookies
    cookieName: string
    expiryInDays?: number
  }) {
    this.#cookieManager = cookieManager
    this.#cookieName = cookieName
    this.#expiryInDays = expiryInDays
  }

  #cookieManager: Cookies
  #cookieName: string
  #expiryInDays?: number

  getSessionId(options?: { extendSession?: boolean }): string | undefined {
    const sessionId = this.#cookieManager.get(this.#cookieName)
    if (options?.extendSession && sessionId) {
      this.setSessionId(sessionId)
    }
    return sessionId
  }

  setSessionId(sessionId: string): void {
    this.#cookieManager.set(this.#cookieName, sessionId, {
      path: '/',
      sameSite: 'strict',
      maxAge: this.#expiryInSeconds(),
    })
  }

  clearSessionId(): void {
    this.#cookieManager.remove(this.#cookieName)
  }

  #expiryInSeconds(): number | undefined {
    if (!this.#expiryInDays) {
      return undefined
    }

    return this.#expiryInDays * SECONDS_IN_DAY
  }
}
