import React from 'react'
import { LinkBox as ChakraLinkBox } from '@chakra-ui/react'
import { As, HubStyleObject, forwardRef } from '@hub/design-system-base'

interface LinkBoxProps {
  as?: As
  sx?: HubStyleObject
  onClick?: React.MouseEventHandler
}

export const LinkBox = forwardRef<LinkBoxProps, typeof ChakraLinkBox>(
  ({ children, as, sx, onClick }, ref) => (
    <ChakraLinkBox ref={ref} as={as} sx={sx} onClick={onClick}>
      {children}
    </ChakraLinkBox>
  )
)
