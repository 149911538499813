/*
It's important we don't use @scentregroup/analytics in the monorepo 
outside of src/shared! Otherwise there can be a mismatch between the
React Context objects instantiated by imports of
@scentregroup/analytics in different applications.

Instead, if an application needs something from @scentregroup/analytics
we re-export it here:
*/

export {
  AnalyticsProviderSimplified as AnalyticsProvider,
  AnalyticsHandlerRegistrantSimplified as AnalyticsHandlerRegistrant,
  useAnalyticsSimplified as useAnalytics,
  useTrackComponentSimplified as useTrackComponentSimplified,
  useDatalayerAnalyticsHandler,
} from '@scentregroup/analytics'

export * from './types'
export { default as AnalyticsProvidersAndInit } from './analytics-providers-and-init'
