import { random } from 'lodash'
import {
  evaluateFeature,
  FeatureRuleSet,
} from 'feature-management-evaluation-engine'

export interface FeatureEvaluationResult<T> {
  cohortName: string | null
  value: T | null
}

export interface FeatureEvaluationContext {
  'time.timestampUTC': number
  'router.path'?: string
  'router.query'?: string
  'user.region'?: string
  'user.loggedIn'?: string
}

export interface FeatureCollectionEvaluationResult<T> {
  featureSlug: string
  cohortName: string | null
  flag: string
  value: T
  experimentId?: string
  variantId?: string
}

export interface FeatureCollectionEvaluation<T> {
  seed: number
  results: Record<string, FeatureCollectionEvaluationResult<T>>
  flags: Record<string, T>
}

const isThere = <T>(underQuestion: T | null | undefined): underQuestion is T =>
  Boolean(underQuestion)

// Moving codebases? Start a conversation about whether
// we should include this in the engine's repo
export const evaluateFeatureCollection = <T>(
  featureCollection: FeatureRuleSet[],
  context: FeatureEvaluationContext,
  overrideSeed?: number
): FeatureCollectionEvaluation<T> => {
  // eslint-disable-next-line no-magic-numbers
  const seed = overrideSeed ?? random(0, 2 ** 32 - 1) // 0 to uint32 max (4294967295)

  const results = featureCollection
    .map(featureRules => {
      const result = evaluateFeature<FeatureEvaluationResult<T>>(
        featureRules,
        context,
        seed
      )

      return evaluationResultToFlag(featureRules, result)
    })
    .filter(isThere)

  return {
    seed,
    results: results.reduce(
      (results, result) => ({ ...results, [result.flag]: result }),
      {}
    ),
    flags: results.reduce(
      (flags, result) => ({ ...flags, [result.flag]: result.value }),
      {}
    ),
  }
}

export const evaluationResultToFlag = <T>(
  featureRules: FeatureRuleSet,
  result: FeatureEvaluationResult<T> | null
): FeatureCollectionEvaluationResult<T> | null => {
  if (!result || result.value === null) {
    return null
  }

  const baseFlagSlug = extractFlagSlugFromFeatureSlug(featureRules.featureSlug)

  const featureManagementCohortNameData =
    extractFeatureManagementDataFromCohortName(
      baseFlagSlug,
      result.cohortName ?? ''
    )

  return {
    featureSlug: featureRules.featureSlug,
    value: result.value,
    ...{ cohortName: result.cohortName, flag: flagSlugToFlag(baseFlagSlug) },
    ...(featureManagementCohortNameData ?? {}),
  }
}

const flagSlugToFlag = (flagSlug: string): string =>
  `NEXT_PUBLIC_FEAT_FLAG_${flagSlug.toUpperCase().split('-').join('_')}`

const extractFlagSlugFromFeatureSlug = (featureSlug: string): string => {
  const slugParts = featureSlug.split('/')
  const flagSlug = slugParts[slugParts.length - 1]

  return flagSlug
}

interface FeatureManagementDataFromCohortName {
  cohortName: string
  flag: string
}

const extractFeatureManagementDataFromCohortName = (
  baseSlug: string,
  maybeFeatureManagementCohortName: string
): FeatureManagementDataFromCohortName | undefined => {
  if (!maybeFeatureManagementCohortName.startsWith('FM:')) {
    return undefined
  }

  const [_, cohortName, flagSlugSuffix] =
    maybeFeatureManagementCohortName
      .split(':')
      .flatMap(part => part.split('|')) ?? []

  if (!cohortName) {
    return undefined
  }

  const flagSlug = flagSlugSuffix
    ? [baseSlug, flagSlugSuffix].join('-')
    : baseSlug

  return {
    cohortName,
    flag: flagSlugToFlag(flagSlug),
  }
}
