export function isDefinedAndNotNull<T>(
  value: T | null | undefined
): value is T {
  return value !== null && value !== undefined
}

export function isEntryDefinedAndNotNull<TKey, TValue>(
  entry: [TKey, TValue | null | undefined]
): entry is [TKey, TValue] {
  const [, value] = entry
  return isDefinedAndNotNull(value)
}

// This should replicate the behaviour of things like
// propTypes that print an error in dev and are silent
// in production
export function printError(msg: string): void {
  if (process.env.NODE_ENV === 'development') {
    console.error(msg)
  }
}
