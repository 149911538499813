import React from 'react'
import * as Sentry from '@sentry/nextjs'

import { AnalyticsHandlerRegistrantSimplified as Registrant } from '@scentregroup/analytics'
import { Handler } from '../analytics/types'

const handler: Omit<Handler, 'trackEvent' | 'trackPage' | 'updateProfile'> = {
  setUserId(userId) {
    if (userId) {
      Sentry.setUser({ id: userId })
    } else {
      Sentry.setUser(null)
    }
  },
}

export const SentryAnalyticsHandler: React.FC<
  React.PropsWithChildren<unknown>
> = () => {
  return <Registrant {...handler} />
}
