import { ApolloLink, HttpOptions } from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import type { Auth0ContextInterface } from '@auth0/auth0-react'
import Cookies from 'universal-cookie'
import { setContext } from '@apollo/client/link/context'
import { isInsecurelyValidJWT } from '../../helpers/jwt'
import { setSession } from '../../hooks/use-session'
import { RawCookieName } from '../../constants'

interface AuthenticationLinkOptions {
  auth0?: Pick<
    Auth0ContextInterface,
    'getAccessTokenSilently' | 'getIdTokenClaims'
  > | null
  cookies?: Cookies
}

export const makeAuthenticationTokenLink = ({
  auth0,
  cookies,
}: AuthenticationLinkOptions): ApolloLink => {
  const cookieManager = cookies ?? new Cookies()

  return setContext(async (_, prevContext) => {
    if (!auth0) {
      return prevContext
    }

    const idTokenFromCookie: string | undefined = cookieManager.get(
      RawCookieName.ID_TOKEN,
      {
        doNotParse: true,
      }
    )

    // Check the exp and nbf timestamps inside the JWT. We do not want a leeway
    // (defaults to 60s) as if we use the idToken after expiry, it will 401 and
    // log us out.
    if (
      idTokenFromCookie &&
      isInsecurelyValidJWT(idTokenFromCookie, { leeway: 0 })
    ) {
      return { ...prevContext, idToken: idTokenFromCookie }
    }

    // Bail out completely if there is no idToken; otherwise, we would check
    // against Auth0 before every request while logged out, which is costly.
    //
    // You can opt-in to checking the session by passing `checkForSession: true`
    // to the context.
    if (!idTokenFromCookie && !prevContext.checkForSession) {
      return prevContext
    }

    try {
      // Since we instantiate the client on its own (ie. with
      // `new Auth0Client`), we need to first check the session.
      // Without this, `getIdTokenClaims` fails silently.
      // See https://github.com/auth0/auth0-spa-js#creating-the-client
      await auth0.getAccessTokenSilently()
      const claims = await auth0.getIdTokenClaims()

      if (claims === undefined) {
        return prevContext
      }

      const idToken = claims.__raw

      // Save the fresh ID token for future use
      setSession(cookieManager.set.bind(cookieManager), idToken)

      return { ...prevContext, idToken }
    } catch {
      return prevContext
    }
  })
}

export const makeAuthenticationResetLink = ({
  cookies,
}: AuthenticationLinkOptions): ApolloLink => {
  const cookieManager = cookies ?? new Cookies()

  return onError(({ graphQLErrors }) => {
    if (graphQLErrors?.some(e => e.message === 'Unauthenticated')) {
      cookieManager.remove(RawCookieName.ID_TOKEN, { path: '/' })
    }
  })
}

export const makeAuthorizationLink = (): ApolloLink =>
  new ApolloLink((operation, forward) => {
    const idToken: string | undefined = operation.getContext().idToken

    operation.setContext(({ headers }: HttpOptions) => ({
      headers: {
        ...headers,
        ...(idToken &&
          isInsecurelyValidJWT(idToken) && {
            Authorization: `Bearer ${idToken}`,
          }),
      },
    }))
    return forward(operation)
  })
