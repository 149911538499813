import { useEffect, useCallback } from 'react'
import { useRouter } from 'next/router'

import { FeatureFlagName } from '../feature-flags'
import useFeatureFlag from './use-feature-flag'

export type SessionLogger = (...messages: any[]) => void

const useSessionLogger = (): SessionLogger => {
  const router = useRouter()
  const isEnabled = useFeatureFlag(
    FeatureFlagName.NEXT_PUBLIC_FEAT_FLAG_SESSION_LOGGER
  )

  useEffect(() => {
    if (isEnabled) {
      console.debug('session', 'page', router?.pathname)
    }
  }, [isEnabled, router?.pathname])

  return useCallback(
    (...messages: any[]) => {
      if (isEnabled) {
        console.debug('session', ...messages)
      }
    },
    [isEnabled]
  )
}

export default useSessionLogger
