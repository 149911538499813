import { colors, isDark, lighten, darken } from '@hub/design-system-base'

type AccessibleColor = {
  bg?: string
  border?: string
  color?: string
  hoverBg?: string
  hoverColor?: string
  hoverColorAlt?: string // used for text color when not changing background color
  activeBg?: string
  activeBorder?: string
  activeColor?: string
  disabledBg?: string
  disabledBorder?: string
  text?: string
  activeText?: string
  disabledText?: string
  hoverBgOutline?: string
  hoverColorOutline?: string
  hoverBorderOutline?: string
  disabledBgOutline?: string
  disabledBorderOutline?: string
  hoverColorTransparent?: string
}

const HOVER_CHANGE = 10
const ACTIVE_CHANGE = 50

const accessibleColorScheme: Record<string, AccessibleColor> = Object.keys(
  colors
).reduce(
  (previousColors, color) => {
    const baseColor = isDark(color)({ colors }) ? 'white' : 'licorice'
    const hoverBg = isDark(color)({ colors })
      ? lighten(color, HOVER_CHANGE)({ colors })
      : darken(color, HOVER_CHANGE)({ colors })
    const hoverColor =
      hoverBg && isDark(hoverBg)({ colors }) ? 'white' : 'licorice'
    const activeBg = isDark(color)({ colors })
      ? lighten(color, ACTIVE_CHANGE)({ colors })
      : darken(color, ACTIVE_CHANGE)({ colors })
    const activeColor =
      activeBg && isDark(activeBg)({ colors }) ? 'white' : 'licorice'
    return {
      [color]: {
        bg: color,
        color: baseColor,
        hoverBg,
        hoverColor,
        activeBg,
        activeColor,
      },
      // goes after on purpose to accept overrides from initial object
      ...previousColors,
    }
  },
  {
    surfacePrimaryWithIcon: {
      bg: colors.surfacePrimary,
      color: colors.iconSurfacePrimary,
      hoverBg: colors.surfacePrimaryHover,
      hoverColor: colors.iconSurfacePrimary,
      disabledText: colors.iconDisabled,
      disabledBg: colors.surfacePrimaryDisabled,
      hoverBgOutline: colors.surfaceBrandSecondary,
      hoverColorOutline: colors.textPrimary,
      hoverBorderOutline: colors.borderPrimary,
      disabledBgOutline: 'transparent',
      disabledBorderOutline: 'transparent',
      hoverColorTransparent: colors.textSecondary,
    },
    surfacePrimaryWithText: {
      bg: colors.surfacePrimary,
      color: colors.textSurfacePrimary,
      hoverBg: colors.surfacePrimaryHover,
      hoverColor: colors.textSurfacePrimary,
      disabledText: colors.textDisabled,
      disabledBg: colors.surfacePrimaryDisabled,
      hoverBgOutline: colors.surfaceBrandSecondary,
      hoverColorOutline: colors.textPrimary,
      hoverBorderOutline: colors.borderPrimary,
      disabledBgOutline: 'transparent',
      disabledBorderOutline: 'transparent',
      hoverColorTransparent: colors.textSecondary,
    },
    surfaceTertiaryWithIcon: {
      bg: colors.surfaceTertiary,
      color: colors.textPrimary,
      hoverBg: colors.backgroundPrimary,
      hoverColor: colors.textPrimary,
    },
    white: {
      bg: 'white',
      color: 'licorice',
      hoverBg: 'smoke',
      hoverColor: 'licorice',
      activeBg: 'ghost',
      activeColor: 'licorice',
    },
    smoke: {
      bg: 'smoke',
      color: 'licorice',
      hoverBg: 'ghost',
      hoverColor: 'licorice',
      hoverColorAlt: 'quartz',
      activeBg: 'manatee',
      activeColor: 'licorice',
    },
    smokeQuartz: {
      bg: 'smoke',
      color: 'quartz',
      hoverBg: 'ghost',
      hoverColor: 'licorice',
      activeBg: 'manatee',
      activeColor: 'quartz',
    },
    ghost: {
      bg: 'ghost',
      color: 'licorice',
      hoverBg: 'manatee',
      hoverColor: 'licorice',
      activeBg: 'quartz',
      activeColor: 'white',
    },
    manatee: {
      bg: 'manatee',
      color: 'licorice',
      hoverBg: 'ghost',
      hoverColor: 'licorice',
      activeBg: 'smoke',
      activeColor: 'licorice',
    },
    quartz: {
      bg: 'quartz',
      color: 'white',
      hoverBg: 'manatee',
      hoverColor: 'white',
      activeBg: 'licorice',
      activeColor: 'white',
    },
    licorice: {
      bg: 'licorice',
      color: 'white',
      hoverBg: 'quartz',
      hoverColor: 'white',
      activeBg: 'manatee',
      activeColor: 'white',
      border: 'ghost',
      activeBorder: 'licorice',
      disabledBg: 'smoke',
      disabledBorder: 'ghost',
      activeText: 'licorice',
      text: 'quartz',
      disabledText: 'ghost',
    },
  }
)

const sizes = {
  xs: {
    height: '2.5em',
    minWidth: 'size-sm',
    fontSize: 'font-xs',
  },
  sm: {
    height: '2.5em',
    minWidth: 'size-sm',
    fontSize: 'font-sm',
  },
  md: {
    height: '2.5em',
    minWidth: 'size-sm',
    fontSize: 'font-md',
  },
  lg: {
    height: '2.5em',
    minWidth: 'size-sm',
    fontSize: 'font-lg',
  },
  xl: {
    height: '2.5em',
    minWidth: 'size-sm',
    fontSize: 'font-xl',
  },
  '2xl': {
    height: '2.5em',
    minWidth: 'size-sm',
    fontSize: 'font-2xl',
    padding: 'spacing-lg',
  },
  '3xl': {
    height: '2.5em',
    minWidth: 'size-sm',
    fontSize: 'font-3xl',
    padding: 'spacing-lg',
  },
  '4xl': {
    height: '2.5em',
    minWidth: 'size-sm',
    fontSize: 'font-4xl',
    padding: 'spacing-lg',
  },
  '5xl': {
    height: '2.5em',
    minWidth: 'size-sm',
    fontSize: 'font-5xl',
    padding: 'spacing-xl',
  },
  '6xl': {
    height: '2.5em',
    minWidth: 'size-sm',
    fontSize: 'font-6xl',
    padding: 'spacing-2xl',
  },
  '7xl': {
    height: '2.5em',
    minWidth: 'size-sm',
    fontSize: 'font-7xl',
    padding: 'spacing-2xl',
  },
  'icon-lg': {
    minWidth: 'size-8',
    height: 'size-8',
    padding: 0,
  },
}

export const buttonTheme = {
  baseStyle: {
    fontWeight: 'bold',
    fontSize: 'font-body',
    padding: 'spacing-md',
    fontFamily: 'body',
    textTransform: 'uppercase',
    backgroundColor: 'white',
  },
  defaultProps: {
    size: 'md',
    variant: 'solid',
    colorScheme: 'ghost',
  },
  sizes: sizes,
  variants: {
    solid: function variantSolid({ colorScheme }: { colorScheme: string }) {
      const accessibleColors = accessibleColorScheme[colorScheme]
      return {
        backgroundColor: accessibleColors.bg,
        color: accessibleColors.color,
        _hover: {
          '@media (hover: hover)': {
            backgroundColor: accessibleColors.hoverBg,
            color: accessibleColors.hoverColor,
          },
        },
        _active: {
          backgroundColor: accessibleColors.activeBg,
          color: accessibleColors.activeColor,
        },
        _disabled: {
          backgroundColor: accessibleColors.disabledBg ?? accessibleColors.bg,
          color: accessibleColors.disabledText ?? accessibleColors.color,
          pointerEvents: 'none',
        },
      }
    },
    outline: function variantOutline({ colorScheme }: { colorScheme: string }) {
      const accessibleColors = accessibleColorScheme[colorScheme]
      return {
        backgroundColor: 'transparent',
        color: accessibleColors.bg,
        border: '2px solid',
        borderColor: accessibleColors.bg,
        _hover: {
          '@media (hover: hover)': {
            color:
              accessibleColors.hoverColorOutline ?? accessibleColors.hoverBg,
            borderColor:
              accessibleColors.hoverBorderOutline ?? accessibleColors.hoverBg,
            backgroundColor: accessibleColors.hoverBgOutline ?? 'transparent',
          },
        },
        _disabled: {
          color: accessibleColors.disabledText ?? accessibleColors.bg,
          borderColor:
            accessibleColors.disabledBorderOutline ?? accessibleColors.hoverBg,
          backgroundColor: accessibleColors.disabledBgOutline ?? 'transparent',
          pointerEvents: 'none',
        },
      }
    },
    transparent: function variantTransparent({
      colorScheme,
      size,
      leftIcon,
    }: {
      colorScheme: string
      size: keyof typeof sizes | undefined
      leftIcon?: unknown
    }) {
      const accessibleColors = accessibleColorScheme[colorScheme]
      const sizeStyles = size && sizes[size]
      return {
        ...sizeStyles,
        backgroundColor: 'transparent',
        color: accessibleColors.bg,
        _hover: {
          '@media (hover: hover)': {
            color:
              accessibleColors.hoverColorTransparent ??
              accessibleColors.hoverBg,
          },
        },
        _disabled: {
          color: accessibleColors.disabledText ?? accessibleColors.bg,
          pointerEvents: 'none',
        },
        minWidth: sizeStyles?.minWidth ?? '44px', // keeps the hit target big enough
        ...(leftIcon && size === 'icon-lg'
          ? {
              // reset Chakra icon:label margin and set to make the icon 48x48
              // assumes icon box-size is 24x24
              '& > span:first-child': {
                marginInlineEnd: 0,
                marginInline: 'calc(var(--chakra-sizes-size-icon-md) / 2)',
              },
            }
          : null),
        minHeight: 48,
      }
    },
    link: function variantLink({ colorScheme }: { colorScheme: string }) {
      const accessibleColors = accessibleColorScheme[colorScheme]
      return {
        backgroundColor: 'transparent',
        color: accessibleColors.bg,
        _hover: {
          '@media (hover: hover)': {
            textDecoration: 'underline',
          },
        },
        minWidth: '44px', // keeps the hit target big enough
      }
    },
    plainText: function variantPlainText({
      colorScheme,
    }: {
      colorScheme: string
    }) {
      const accessibleColors = accessibleColorScheme[colorScheme]
      return {
        backgroundColor: 'transparent',
        color: accessibleColors.bg,
        _hover: {
          '@media (hover: hover)': {
            textDecoration: 'underline',
          },
        },
        minWidth: '44px', // keeps the hit target big enough
        fontWeight: 'normal',
        textDecoration: 'underline',
        textTransform: 'unset',
        paddingX: 0,
      }
    },
    select: function variantSelect({ colorScheme }: { colorScheme: string }) {
      const accessibleColors = accessibleColorScheme[colorScheme]
      return {
        minWidth: '44px', // keeps the hit target big enough
        textTransform: 'unset',
        paddingTop: 0,
        paddingBottom: 0,
        border: '1px solid',
        borderColor: accessibleColors.border || accessibleColors.bg,
        fontWeight: 'normal',
        color: accessibleColors.text || accessibleColors.bg,
        _active: {
          fontWeight: 'bold',
          borderColor:
            accessibleColors.activeBorder || accessibleColors.activeBg,
          color: accessibleColors.activeText || accessibleColors.bg,
        },
        _disabled: {
          backgroundColor: accessibleColors.disabledBg || accessibleColors.bg,
          color: accessibleColors.disabledText || accessibleColors.color,
          borderColor:
            accessibleColors.disabledBorder ||
            accessibleColors.disabledBg ||
            accessibleColors.bg,
          pointerEvents: 'none',
        },
      }
    },
    toggle: function variantToggle({ colorScheme }: { colorScheme: string }) {
      const accessibleColors = accessibleColorScheme[colorScheme]
      return {
        minWidth: '44px', // keeps the hit target big enough
        textTransform: 'unset',
        border: '1px solid',
        borderColor: accessibleColors.bg,
        color: accessibleColors.bg,
        _active: {
          backgroundColor: accessibleColors.bg,
          color: accessibleColors.color,
        },
      }
    },
    lowerLink: function variantLowerLink({
      colorScheme,
    }: {
      colorScheme: string
    }) {
      const accessibleColors = accessibleColorScheme[colorScheme]
      return {
        minWidth: '44px', // keeps the hit target big enough
        textTransform: 'unset',
        fontWeight: '400',
        border: '1px solid',
        borderColor: accessibleColors.color,
        backgroundColor: accessibleColors.bg,
        color: accessibleColors.text || accessibleColors.color,
        _hover: {
          '@media (hover: hover)': {
            textDecoration: 'underline',
            color: accessibleColors.hoverColor,
          },
        },
      }
    },
    tile: function variantTile({ colorScheme }: { colorScheme: string }) {
      const accessibleColors = accessibleColorScheme[colorScheme]
      return {
        backgroundColor: 'transparent',
        color: accessibleColors.bg,
        _hover: {
          '@media (hover: hover)': {
            textDecoration: 'underline',
          },
        },
        minWidth: '44px', // keeps the hit target big enough
        fontWeight: 'normal',
        textDecoration: 'underline',
        textTransform: 'unset',
        paddingX: 0,
        height: 88,
        width: '100%',
      }
    },
    utilityOutline: function variantUtilityOutline({
      colorScheme,
    }: {
      colorScheme: string
    }) {
      const accessibleColors = accessibleColorScheme[colorScheme]
      return {
        minWidth: '44px', // keeps the hit target big enough
        backgroundColor: 'transparent',
        color: accessibleColors.color,
        border: 1,
        borderStyle: 'solid',
        borderColor: accessibleColors.bg,
        fontWeight: 'normal',
        textTransform: 'none',
        padding: 0,
      }
    },
    utilityOutlineMobile: function variantUtilityOutlineMobile({
      colorScheme,
    }: {
      colorScheme: string
    }) {
      const accessibleColors = accessibleColorScheme[colorScheme]
      return {
        minWidth: '44px',
        backgroundColor: 'transparent',
        color: accessibleColors.color,
        borderTop: '1px',
        borderBottom: '1px',
        borderStyle: 'solid',
        borderColor: accessibleColors.bg,
        fontWeight: 'normal',
        textTransform: 'none',
        padding: 0,
      }
    },
    plainIcon: function variantPlainIcon({
      colorScheme,
    }: {
      colorScheme: string
    }) {
      const accessibleColors = accessibleColorScheme[colorScheme]
      return {
        color: accessibleColors.bg,
        backgroundColor: 'transparent',
        minWidth: 44,
        width: 44,
      }
    },
    searchBar: function variantSearchBar({
      size,
      leftIcon,
    }: {
      colorScheme: string
      size: keyof typeof sizes | undefined
      leftIcon: unknown
    }) {
      const sizeStyles = size && sizes[size]
      return {
        ...sizeStyles,
        backgroundColor: 'surfaceTertiary',
        color: 'textSecondary',
        fontWeight: 'normal',
        textTransform: 'none',
        justifyContent: 'normal',
        padding: 0,
        paddingLeft: 'spacing-sm',
        border: '1px',
        borderColor: 'borderSecondary',
        _hover: {
          color: 'textSecondary', // TODO: should there be a hover effect
        },
        ...(leftIcon && size === 'icon-lg'
          ? {
              // reset Chakra icon:label margin and set to make the icon 48x48
              // assumes icon box-size is 16x16
              '& > span:first-child': {
                marginInlineEnd: 0,
                marginInline: 'calc(var(--chakra-sizes-size-icon-sm) / 2)',
                color: 'iconPrimary',
              },
            }
          : null),
      }
    },
    menuButton: function variantMenuButton() {
      return {
        backgroundColor: 'transparent',
        color: 'textPrimary',
        _hover: {
          textDecoration: 'underline',
        },
        minWidth: '44px', // keeps the hit target big enough
        minHeight: 48,
      }
    },
    headerButton: function variantHeaderButton() {
      return {
        backgroundColor: 'transparent',
        color: 'textPrimary',
        _hover: {
          textDecoration: 'underline',
        },
        minHeight: 48,
      }
    },
  },
}
