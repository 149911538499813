import { useCallback } from 'react'
import { useMutation } from '@apollo/client'
import type { ResultOf } from '@graphql-typed-document-node/core'
import { MarketplaceApplyCouponCodeDocument } from '..'

type MarketplaceCoupon = ResultOf<
  typeof MarketplaceApplyCouponCodeDocument
>['coupon']

export function useMarketplaceApplyCouponCode(): (
  couponCode: string,
  cb: (coupon: MarketplaceCoupon | null) => void
) => void {
  const [marketplaceApplyCouponCode] = useMutation(
    MarketplaceApplyCouponCodeDocument,
    {
      context: { version: 2 },
    }
  )

  return useCallback(
    async (
      couponCode: string,
      cb: (coupon: MarketplaceCoupon | null) => void
    ) => {
      const { data } = await marketplaceApplyCouponCode({
        variables: {
          couponCode,
        },
      })

      cb(data?.coupon ?? null)
    },
    [marketplaceApplyCouponCode]
  )
}
