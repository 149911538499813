import React, { useEffect } from 'react'
import { useRouter } from 'next/router'
import { withClientOnly } from '../client-only'
import { useMarketplaceApplyCouponCode } from '../../graphql'

const CouponCodeDetector: React.FC<React.PropsWithChildren<unknown>> = () => {
  const router = useRouter()

  const marketplaceApplyCouponCode = useMarketplaceApplyCouponCode()

  const path = router.asPath
  const pathHashIndex = path.indexOf('#')
  const pathWithoutHash =
    pathHashIndex === -1 ? path : path.slice(0, pathHashIndex)
  const pathHash = pathHashIndex === -1 ? '' : path.slice(pathHashIndex)

  const couponCode = extractCouponCodeFromHash(pathHash)
  useEffect(() => {
    if (couponCode) {
      marketplaceApplyCouponCode(couponCode, () => {
        router.replace(pathWithoutHash, undefined, {
          shallow: true,
          scroll: false,
        })
      })
    }
  }, [couponCode, marketplaceApplyCouponCode, router, pathWithoutHash])

  return <></>
}

export function extractCouponCodeFromHash(hash: string): string | undefined {
  const couponHashPrefix = '#coupon:'
  if (hash.startsWith(couponHashPrefix)) {
    const coupon = hash.slice(couponHashPrefix.length)
    if (coupon) {
      return coupon
    }
  }
  return undefined
}

export default withClientOnly(CouponCodeDetector)
