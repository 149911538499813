import React from 'react'
import { useToken, Box } from '@chakra-ui/react'
import {
  As,
  HubStyleObject,
  StandardSpacings,
  forwardRef,
} from '@hub/design-system-base'

interface ImposterProps {
  /**
   * An element to overlay / impose upon the children
   */
  impose?: React.ReactElement<any>
  as?: As
  className?: string
  /**
   * Where the imposed element is placed within the box containing the children
   */
  position?:
    | 'center'
    | 'top'
    | 'right'
    | 'bottom'
    | 'left'
    | 'top-left'
    | 'top-right'
    | 'bottom-left'
    | 'bottom-right'
  /**
   * A psuedo-margin placed around the imposed element to give it space from the
   * edges of the box containing the children
   */
  margin?: StandardSpacings
  /**
   * A psuedo-margin placed on the left & right of the imposed element to give
   * it space from the edges of the box containing the children. If unset, will
   * inherit the value of `margin`.
   */
  marginX?: StandardSpacings
  /**
   * A psuedo-margin placed on the top & bottom of the imposed element to give
   * it space from the edges of the box containing the children. If unset, will
   * inherit the value of `margin`.
   */
  marginY?: StandardSpacings
  /**
   * A psuedo-margin placed on the left of the imposed element to give it space
   * from the edges of the box containing the children. If unset, will inherit
   * the value of `marginX`, or `margin` in that order.
   */
  marginLeft?: StandardSpacings
  /**
   * A psuedo-margin placed on the right of the imposed element to give it space
   * from the edges of the box containing the children. If unset, will inherit
   * the value of `marginX`, or `margin` in that order.
   */
  marginRight?: StandardSpacings
  /**
   * A psuedo-margin placed on the top of the imposed element to give it space
   * from the edges of the box containing the children. If unset, will inherit
   * the value of `marginY`, or `margin` in that order.
   */
  marginTop?: StandardSpacings
  /**
   * A psuedo-margin placed on the bottom of the imposed element to give it
   * space from the edges of the box containing the children. If unset, will
   * inherit the value of `marginY`, or `margin` in that order.
   */
  marginBottom?: StandardSpacings
  /**
   * Should the imposed item be constrained to within the container? This is
   * useful for imposing items that have wrapping content (flex containers,
   * text, etc) to ensure they don't accidentally grow outside of the container.
   */
  constrained?: boolean
  sx?: HubStyleObject
}

export const Imposter = forwardRef<ImposterProps, typeof Box>(
  (
    {
      as,
      impose,
      children,
      position = 'center',
      margin = 'spacing-none',
      marginX = margin,
      marginY = margin,
      marginLeft = marginX,
      marginRight = marginX,
      marginTop = marginY,
      marginBottom = marginY,
      constrained = true,
      className,
      sx,
    },
    ref
  ) => {
    const [
      marginTopToken,
      marginRightToken,
      marginBottomToken,
      marginLeftToken,
    ] = (useToken('space', [
      marginTop,
      marginRight,
      marginBottom,
      marginLeft,
    ]) as string[])
      // Ensure the "zero" size is suffixed with "px" so it works with "calc()"
      .map(token => (token === '0' ? '0px' : token))

    return (
      <Box
        ref={ref}
        as={as}
        sx={{ ...(position !== 'center' && { position: 'relative' }), ...sx }}
        className={className}
      >
        {children}
        {React.isValidElement(impose) &&
          React.cloneElement(impose as React.ReactElement<any>, {
            sx: {
              ...((impose?.props as any)?.sx ?? {}),
              position: 'absolute',
              ...(constrained && {
                maxWidth: `calc(100% - calc(${marginLeftToken} + ${marginRightToken}))`,
                maxHeight: `calc(100% - calc(${marginTopToken} + ${marginBottomToken}))`,
              }),
              ...(position === 'center' && {
                left: '50%',
                top: '50%',
                transform: 'translate(-50%, -50%)',
              }),
              ...(position.endsWith('left') && {
                left: marginLeftToken,
              }),
              ...(position.endsWith('right') && {
                right: marginRightToken,
              }),
              ...(position.startsWith('top') && {
                top: marginTopToken,
              }),
              ...(position.startsWith('bottom') && {
                bottom: marginBottomToken,
              }),
              ...(['left', 'right'].includes(position) && {
                top: '50%',
                transform: 'translateY(-50%)',
              }),
              ...(['top', 'bottom'].includes(position) && {
                left: '50%',
                transform: 'translateX(-50%)',
              }),
            },
          })}
      </Box>
    )
  }
)
