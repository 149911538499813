import { useQuery } from '@apollo/client'
import { IUser } from '../types/user'
import { GetUserDocument } from '../graphql'
import { transformUserData } from '../helpers/transforms'
import useIsMounted from './use-is-mounted'
import useIdToken from './use-id-token'

export function useUser(includeAuthHeader = true): {
  user?: IUser
  loading: boolean
} {
  const { idToken } = useIdToken()
  const isBrowser = useIsMounted()

  let context: any = { version: 2 }

  if (includeAuthHeader) {
    context = { ...context, headers: { Authorization: `Bearer ${idToken}` } }
  }

  const { data, loading } = useQuery(GetUserDocument, {
    context,
    skip: !isBrowser || !idToken,
    errorPolicy: 'ignore',
    fetchPolicy: 'network-only',
  })

  const fitleredUserData = transformUserData(data)

  return {
    user: fitleredUserData,
    loading: !isBrowser ? true : loading,
  }
}
