// From: https://github.com/vercel/next.js/issues/4804#issuecomment-606624325
import { useRouter as useNextRouter, NextRouter } from 'next/router'
import { ParsedUrlQuery } from 'querystring'
import React from 'react'

/**
 * Given a string such as:
 *
 * https://example.com/foo?bar=zip&name=Sam
 *
 * Will return:
 *
 * {
 *   bar: 'zip',
 *   name: 'Sam',
 * }
 */
const queryFromUrl = (url: string): ParsedUrlQuery => {
  const [, ...queryStrings] = url.split('?')
  const queryString = queryStrings.join('?')
  const query: ParsedUrlQuery = {}

  for (const [key, value] of new URLSearchParams(queryString).entries()) {
    query[key] = value
  }

  return query
}

const extractQueryFromRouter = (router: NextRouter): ParsedUrlQuery => ({
  ...queryFromUrl(router.asPath),
  ...router.query,
})

/**
 * Provide a router hook which ensures router.query is always correctly hydrated
 * on the first render even when statically optimised to avoid [this caveat from
 * the docs](https://nextjs.org/docs/routing/dynamic-routes):
 *
 * > Pages that are statically optimized by Automatic Static Optimization will
 * > be hydrated without their route parameters provided, i.e `query` will be an
 * > empty object (`{}`).
 *
 * Usage is identical to `import { useRouter } from 'next/router';
 */
export const useRouter = (): NextRouter => {
  const router = useNextRouter()
  router.query = extractQueryFromRouter(router)
  return router
}

export const useIsRouteChanging = (): boolean => {
  const router = useRouter()
  const [isRouteChanging, setRouteChanging] = React.useState(false)

  const routeChangeStart = React.useCallback(
    (_url: any, { shallow }: any): void => {
      if (!shallow) {
        setRouteChanging(true)
      }
    },
    [setRouteChanging]
  )

  const routeChangeCompleteOrError = React.useCallback((): void => {
    setRouteChanging(false)
  }, [setRouteChanging])

  React.useEffect(() => {
    router.events.on('routeChangeStart', routeChangeStart)
    router.events.on('routeChangeComplete', routeChangeCompleteOrError)
    router.events.on('routeChangeError', routeChangeCompleteOrError)

    return () => {
      router.events.off('routeChangeStart', routeChangeStart)
      router.events.off('routeChangeComplete', routeChangeCompleteOrError)
      router.events.off('routeChangeError', routeChangeCompleteOrError)
    }
  }, [router, routeChangeStart, routeChangeCompleteOrError])

  return isRouteChanging
}
