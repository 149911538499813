import { SessionIdStorage } from '.'

export class MemorySessionIdStorage implements SessionIdStorage {
  #sessionId: string | undefined

  getSessionId(): string | undefined {
    return this.#sessionId
  }

  setSessionId(sessionId: string): void {
    this.#sessionId = sessionId
  }
  clearSessionId(): void {
    this.#sessionId = undefined
  }
}
