import { ObjectActionAnalyticsEvent } from '../../analytics/types'

export function transformObjectActionEvent(
  event: ObjectActionAnalyticsEvent
): Record<string, any> | Record<string, any>[] {
  if (event.legacy) {
    return event.legacy
  }

  switch (event.object) {
    case 'test':
      return []
    case 'Cart':
    case 'Centre':
    case 'Checkout':
    case 'Checkout Step':
    case 'Global':
    case 'Order':
    case 'Product':
    case 'Product List':
    case 'Content':
    case 'Navigation':
    case 'Content Impression':
    case 'Preferred Centre':
    case 'Module':
      return []
    default: {
      const _exhaustive: never = event
    }
  }

  return []
}
