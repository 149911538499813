export function isBrowser(): boolean {
  return typeof window !== 'undefined'
}

// This is an edge case for apps opening our webpages through a webview.
// Some Android apps do not have `setDomStorageEnabled` set to true which
// is out of our control so the best we can do is check if `localStorage`
// is accessible
export function isLocalStorageEnabled(): boolean {
  try {
    const key = 'test'
    window.localStorage.setItem(key, key)
    window.localStorage.removeItem(key)
    return true
  } catch {
    return false
  }
}
