/* eslint-disable @typescript-eslint/no-explicit-any */

// Note I don't think we can get away from explicit any in this kind of
// very general dynamic object walking shenanigans, however hopefully
// filter nodes is still reasonably typesafe (so long as its not itself buggy)
// given we constrain the return type match properly against the input type

import pickBy from 'lodash/pickBy'
import isObjectLike from 'lodash/isObjectLike'
import mapValues from 'lodash/mapValues'
import { IUser } from '../types/user'
import { isDefinedAndNotNull } from '../lib'
import { GetUserQuery } from '../graphql'

type Key = string | number
type Collection = Record<string, unknown> | any[]

// N.B. Returning Partial<InputType> is not enough because we recursively
// filter the whole object tree; so we need a recursive version of Partial

export type RecursivePartial<T> = T extends (infer U)[]
  ? RecursivePartialArray<U>
  : T extends Record<string, unknown>
  ? { [P in keyof T]?: RecursivePartial<T[P]> }
  : T

// N.B. you can sometimes define types recursively in aliases, but sometimes not
// https://github.com/Microsoft/TypeScript/issues/12525#issuecomment-263166239
// The following is apparently the standard trick for working around that:
// https://github.com/microsoft/TypeScript/issues/6230
// eslint-disable-next-line @typescript-eslint/no-empty-object-type
interface RecursivePartialArray<T> extends Array<RecursivePartial<T>> {}

// Given an object/array, recursively remove nodes in the object tree
// at given paths for which predicate returns falsy

export function filterNodes<C extends Collection>(
  toBeFiltered: C,
  predicate: (elem: any, path: Key[]) => boolean
): RecursivePartial<C> {
  const seen = new Set()
  function filterNodes_(item: any, path: Key[]): any {
    if (seen.has(item) || !isObjectLike(item)) {
      return item
    }
    seen.add(item)
    // N.B. The creation of large numbers of [...path, key] is likely super
    // inefficient. Rewrite if requiring performance over a large structure
    const guard = (x: any, key: Key): boolean => predicate(x, [...path, key])
    const recurse = (x: any, key: Key): any => filterNodes_(x, [...path, key])
    return Array.isArray(item)
      ? item.filter(guard).map(recurse)
      : mapValues(pickBy(item, guard), recurse)
  }
  return filterNodes_(toBeFiltered, [])
}

export function filterNullsFromItems<C extends Collection>(
  graphqlV2Response: C
): RecursivePartial<C> {
  return filterNodes(graphqlV2Response, function testForNulls(elem, path) {
    const len = path.length
    const GRANDPARENT = 2
    const isItemExcluded =
      len >= GRANDPARENT && path[len - GRANDPARENT] === 'items' && elem === null
    return !isItemExcluded
  })
}

export function transformUserData(
  data?: GetUserQuery | null
): IUser | undefined {
  if (!data?.currentUser) {
    return undefined
  }

  const userData = data.currentUser

  return {
    ...userData,
    firstName: userData.firstName ?? '',
    lastName: userData.lastName ?? '',
    storedPaymentMethods: userData.storedPaymentMethods
      ? userData.storedPaymentMethods.filter(isDefinedAndNotNull)
      : [],
    storedAddresses: userData.storedAddresses
      ? userData.storedAddresses.filter(isDefinedAndNotNull)
      : [],
  }
}
