import React, { useContext } from 'react'
import { Centre } from '../../apps/website/lib/get-centre-by-slug'

import { Link } from './types/link'

import { IUserContextType } from './types/user'
import { FeatureFlags, DEFAULT_FEATURE_FLAGS } from './feature-flags'
import { FeatureCollectionEvaluation } from './lib/remote-feature-management'

export interface ThemeOption {
  luxury?: string
}

export interface UserDeviceOption {
  isUsingTouch?: boolean
}

export const TimeZoneContext = React.createContext('timeZone')
export const CentreContext = React.createContext<Centre | undefined>(undefined)
export const CustomThemeContext = React.createContext({} as ThemeOption)
export const UserDeviceContext = React.createContext({
  isUsingTouch: false,
})
export const BasicPageContext = React.createContext(false)

export type GetHref = () => string

export const GetHrefContext = React.createContext<GetHref>(() => '')
/** TODO - remove this enzyme hack and refactor in React Testing Library
 * This test is skipped as part of REACT-18 and will be part of post-delivery tracked
 * in ticket DXP-737
 * eslint-disable-next-line jest/no-disabled-tests
 **/

// TODO: currently enzyme doesn't support useContext so the testing
// will not working when we use useContext inside the react component
// once this https://github.com/airbnb/enzyme/pull/2041 is released
// we can ditch this in the bin
export const useGetHrefContext = (): GetHref =>
  useContext<GetHref>(GetHrefContext)

// Typing Raven as `any` because getting the client and server types to work
// together seemed too much of a painful diversion. Intentionally did not
// suppress the lint warning to keep this as a visible issue to be addressed
// later
export const RavenContext = React.createContext<any>(undefined)

export const ServerErrorContext = React.createContext<any>(undefined)

export const AgentInfoContext = React.createContext({
  agent: '',
  isIPhone: false,
  isMobile: false,
})

export const WhatsOnSeeMoreContext = React.createContext({})

export const FeatureFlagsContext = React.createContext<
  FeatureFlags & {
    remote?: FeatureCollectionEvaluation<boolean>
  }
>(DEFAULT_FEATURE_FLAGS)

export const PromotionPositionContext = React.createContext<
  string | undefined | null
>(undefined)

export const LinkContext = React.createContext<Link>(() => null)

export const UserContext = React.createContext<IUserContextType>({
  user: undefined,
  loading: false,
})
export const useUser = (): IUserContextType => useContext(UserContext)

export interface AdTargetingContextType {
  centreSlug?: string | null
}

export const AdTargetingContext = React.createContext<AdTargetingContextType>(
  {}
)
