import { TypePolicies } from '@apollo/client'

const apolloClientTypePolicies: TypePolicies = {
  Cart: { fields: { items: { merge: false } } },
  Customer: { keyFields: ['scentregroupId'] },
  GiftCardCartItem: { fields: { recipientDetails: { merge: false } } },
  MarketplaceProductFacet: {
    fields: {
      options: {
        merge: false,
        read(_, { args, toReference }) {
          return toReference({
            __typename: 'MarketplaceProductFacet',
            search: args?.search,
          })
        },
      },
    },
  },
  MarketplaceProductFacetOption: { merge: false },
}

export default apolloClientTypePolicies
