export const spinnerTheme = {
  variants: {
    default: function defaultVariant() {
      return {
        width: '1em',
        height: '1em',
        display: 'inline-block',
      }
    },
    large: function largeVariant() {
      return {
        width: 'calc(40px + 3.2vw)',
        height: 'calc(40px + 3.2vw)',
        'box-sizing': 'border-box',
        top: '128px',
        left: '50%',
        margin: '25vh auto',
        display: 'block',
      }
    },
  },
}
