import React from 'react'
import { Link } from '@hub/link'
import { Button, ButtonProps } from '@hub/button'
import useFeatureFlag from '../../hooks/use-feature-flag'
import { FeatureFlagName } from '../../feature-flags'

export enum MagicLinkValue {
  stores,
  centres,
}

type MagicLinkState = [
  value: null | MagicLinkValue | undefined,
  setValue: (value?: null | MagicLinkValue) => void,
]

const MagicLinkContext = React.createContext<MagicLinkState>([null, () => {}])

export const MagicLinkProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const value = React.useState<MagicLinkState[0]>(null)
  return (
    <MagicLinkContext.Provider value={value}>
      {children}
    </MagicLinkContext.Provider>
  )
}

export const useMagicLink = (): MagicLinkState => {
  return React.useContext(MagicLinkContext)
}

type MagicLinkProps = React.ComponentProps<typeof Link> & {
  buttonVariant?: ButtonProps['variant']
}

const MagicLink: React.FC<React.PropsWithChildren<MagicLinkProps>> = ({
  buttonVariant,
  ...linkProps
}) => {
  const [, setValue] = useMagicLink()
  const storeDirectoryEnabled = useFeatureFlag(
    FeatureFlagName.NEXT_PUBLIC_FEAT_FLAG_ENABLE_STORE_DIRECTORY
  )
  const handleClick = (e: React.MouseEvent): void => {
    linkProps.onClick?.(e)
    e.preventDefault()
    if (linkProps.href === '/stores') {
      setValue(MagicLinkValue.stores)
    }
    if (linkProps.href === '/centres') {
      setValue(MagicLinkValue.centres)
    }
  }
  const { href } = linkProps
  if (href === '/centres' || (!storeDirectoryEnabled && href === '/stores')) {
    return (
      <Link
        {...linkProps}
        as={({ href: _href, ...props }: MagicLinkProps) => {
          const {
            buttonVariant: _buttonVariant,
            colorScheme: _colorScheme,
            type: _type,
            ...linkProps
          } = props
          return (
            <Button
              {...linkProps}
              variant={buttonVariant}
              onClick={handleClick}
            />
          )
        }}
        onClick={handleClick}
      />
    )
  }
  return <Link {...linkProps} />
}

export default MagicLink
