import React from 'react'
import {
  Button as ChakraButton,
  IconButton as ChakraIconButton,
  ButtonGroup as ChakraButtonGroup,
} from '@chakra-ui/react'
import {
  As,
  HubStyleObject,
  forwardRef,
  StandardColors,
  injectTheme,
} from '@hub/design-system-base'
import { buttonTheme } from './theme'

export type HubButtonVariants = keyof typeof buttonTheme.variants

type ButtonColorSchemes =
  | StandardColors
  | 'smokeQuartz'
  | 'surfacePrimaryWithText'

export interface ButtonProps {
  sx?: HubStyleObject
  as?: As
  leftIcon?: React.ComponentProps<typeof ChakraButton>['leftIcon']
  colorScheme?: ButtonColorSchemes
  rightIcon?: React.ComponentProps<typeof ChakraButton>['rightIcon']
  isLoading?: React.ComponentProps<typeof ChakraButton>['isLoading']
  isActive?: React.ComponentProps<typeof ChakraButton>['isActive']
  isDisabled?: React.ComponentProps<typeof ChakraButton>['isDisabled']
  loadingText?: React.ComponentProps<typeof ChakraButton>['loadingText']
  variant?: HubButtonVariants
  type?: 'button' | 'submit' | 'reset'
  size?: React.ComponentProps<typeof ChakraButton>['size']
  onClick?: React.MouseEventHandler
}

injectTheme('Button', buttonTheme)

export const Button = forwardRef<ButtonProps, typeof ChakraButton>(
  (
    {
      sx,
      as,
      colorScheme,
      leftIcon,
      rightIcon,
      isLoading,
      isActive,
      isDisabled,
      loadingText,
      variant,
      size,
      type,
      onClick,
      children,
      ...props
    },
    ref
  ) => (
    <ChakraButton
      {...props}
      ref={ref}
      sx={sx}
      as={as}
      colorScheme={colorScheme}
      leftIcon={leftIcon}
      rightIcon={rightIcon}
      isLoading={isLoading}
      isActive={isActive}
      isDisabled={isDisabled}
      loadingText={loadingText}
      variant={variant}
      size={size}
      {...(type && { type })}
      {...(onClick && { onClick })}
    >
      {children}
    </ChakraButton>
  )
)

type IconButtonColorSchemes =
  | StandardColors
  | 'surfacePrimaryWithIcon'
  | 'surfaceTertiaryWithIcon'

interface IconButtonProps {
  sx?: HubStyleObject
  as?: As
  colorScheme?: IconButtonColorSchemes
  isLoading?: React.ComponentProps<typeof ChakraButton>['isLoading']
  isActive?: React.ComponentProps<typeof ChakraButton>['isActive']
  isDisabled?: React.ComponentProps<typeof ChakraButton>['isDisabled']
  variant?: HubButtonVariants
  size?: React.ComponentProps<typeof ChakraButton>['size']
  icon: React.ComponentProps<typeof ChakraIconButton>['icon']
  onClick?: React.MouseEventHandler
  onMouseDown?: React.MouseEventHandler
  'aria-label': string
}

export const IconButton = forwardRef<IconButtonProps, typeof ChakraIconButton>(
  (
    {
      sx,
      as,
      icon,
      colorScheme,
      isLoading,
      isActive,
      isDisabled,
      variant,
      size,
      onClick,
      onMouseDown,
      children,
      ...rest
    },
    ref
  ) => (
    <ChakraIconButton
      ref={ref}
      sx={sx}
      as={as}
      icon={icon}
      aria-label={rest['aria-label']}
      colorScheme={colorScheme}
      isLoading={isLoading}
      isActive={isActive}
      isDisabled={isDisabled}
      variant={variant}
      size={size}
      onClick={onClick}
      onMouseDown={onMouseDown}
    >
      {children}
    </ChakraIconButton>
  )
)

interface ButtonGroupProps {
  sx?: HubStyleObject
  as?: As
  colorScheme?: StandardColors
  isAttached?: React.ComponentProps<typeof ChakraButtonGroup>['isAttached']
  isDisabled?: React.ComponentProps<typeof ChakraButtonGroup>['isDisabled']
  size?: React.ComponentProps<typeof ChakraButtonGroup>['size']
  spacing?: React.ComponentProps<typeof ChakraButtonGroup>['spacing']
  variant?: 'light' | 'solid' // TODO: How do we get these out of base
}

export const ButtonGroup = forwardRef<
  ButtonGroupProps,
  typeof ChakraButtonGroup
>(
  (
    {
      sx,
      as,
      colorScheme,
      isAttached,
      isDisabled,
      size,
      spacing,
      variant,
      children,
    },
    ref
  ) => (
    <ChakraButtonGroup
      ref={ref}
      sx={sx}
      as={as}
      colorScheme={colorScheme}
      isDisabled={isDisabled}
      variant={variant}
      size={size}
      spacing={spacing}
      isAttached={isAttached}
    >
      {children}
    </ChakraButtonGroup>
  )
)
