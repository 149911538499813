const routerBasePaths = [
  '/shop/brands',
  '/shop',
  '/account',
  '/westfield-plus',
  '/membership',
  '',
]

function pathnameMatchesBase(pathname: string, basePath: string): boolean {
  return pathname === basePath || pathname.startsWith(`${basePath}/`)
}

export function hrefIsOutsideRouter(
  routerPathname: string,
  href: string
): boolean {
  const a = routerBasePaths.find(bp => pathnameMatchesBase(routerPathname, bp))
  const b = routerBasePaths.find(bp => pathnameMatchesBase(href, bp))
  return a !== b
}
