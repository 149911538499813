import { JwtPayload } from 'jwt-decode'

import { decodeJWT } from './jwt'

export enum SG_ID_TOKEN_CLAIMS {
  SCENTREGROUP_ID = 'https://account.westfield.com.au/scentregroup_id',
  PENDING_EMAIL = 'https://account.westfield.com.au/pendingEmail',
}

export interface IdTokenPayload extends JwtPayload {
  [SG_ID_TOKEN_CLAIMS.SCENTREGROUP_ID]?: unknown
  [SG_ID_TOKEN_CLAIMS.PENDING_EMAIL]?: unknown
  email?: unknown
  given_name?: unknown
  family_name?: unknown
  email_verified?: unknown
}

export interface IdToken extends JwtPayload {
  scentregroupId?: string
  email?: string
  firstName?: string
  lastName?: string
  emailPending?: string
  emailVerified?: boolean
}

export function decodeIdToken(jwt: string): IdToken {
  const idToken = decodeJWT<IdTokenPayload>(jwt)

  // Expose some JWT values
  // not before, expiry, issued at
  const { nbf, exp, iat } = idToken

  return {
    exp,
    nbf,
    iat,
    scentregroupId: extractStringClaim(
      idToken,
      SG_ID_TOKEN_CLAIMS.SCENTREGROUP_ID
    ),
    email: extractStringClaim(idToken, 'email'),
    firstName: extractStringClaim(idToken, 'given_name'),
    lastName: extractStringClaim(idToken, 'family_name'),
    emailVerified: extractBooleanClaim(idToken, 'email_verified'),
    emailPending: extractStringClaim(idToken, SG_ID_TOKEN_CLAIMS.PENDING_EMAIL),
  }
}

function extractStringClaim(
  payload: IdTokenPayload,
  claim: keyof IdTokenPayload
): string | undefined {
  const value = payload[claim]
  if (typeof value !== 'string') {
    return undefined
  }

  return value
}

function extractBooleanClaim(
  payload: IdTokenPayload,
  claim: keyof IdTokenPayload
): boolean {
  const value = payload[claim]
  return value !== 'false' && Boolean(value)
}
