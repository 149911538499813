import React from 'react'
import { Center as ChakraCenter } from '@chakra-ui/react'
import {
  As,
  HubStyleObject,
  StandardColors,
  StandardSizes,
  forwardRef,
} from '@hub/design-system-base'

interface CoverProps {
  as?: As
  backgroundColor?: StandardColors
  width?: StandardSizes
  height?: StandardSizes
  sx?: HubStyleObject
}

export const Cover = forwardRef<CoverProps, typeof ChakraCenter>(({
  as,
  backgroundColor,
  width = 'size-full',
  height = 'size-full',
  children,
  sx,
}, ref) => (
  <ChakraCenter
    width={width}
    height={height}
    backgroundColor={backgroundColor}
    sx={sx}
    as={as}
  >
    {children}
  </ChakraCenter>
))
