import React from 'react'
import { UserContext } from '../context'
import { useUser } from '../hooks/use-user'

const UserStateProvider: React.FunctionComponent<
  React.PropsWithChildren<unknown>
> = ({ children }) => {
  const userData = useUser()

  return (
    <UserContext.Provider value={userData}>{children}</UserContext.Provider>
  )
}

export default UserStateProvider
