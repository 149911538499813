import { NormalizedCacheObject, ApolloClient } from '@apollo/client'
import Cookies from 'universal-cookie'
import { makeApolloClient as sharedMakeApolloClient } from '@scentregroup/shared/apollo-client'
import type { Auth0ContextInterface } from '@auth0/auth0-react'

interface IMakeApolloClientOptions {
  requestId?: string
  auth0?: Auth0ContextInterface | null
  cookies?: Record<string, string>
  getSessionId?(): string
  getAnonymousId?(): string | null | undefined
}

export function makeApolloClient({
  requestId,
  auth0,
  cookies,
  getSessionId,
  getAnonymousId,
}: IMakeApolloClientOptions = {}): ApolloClient<NormalizedCacheObject> {
  const urlsByVersion: Record<number, string> = {}
  if (process.env.NEXT_PUBLIC_CONTENT_SERVICE) {
    urlsByVersion[1] = process.env.NEXT_PUBLIC_CONTENT_SERVICE
  }
  if (process.env.NEXT_PUBLIC_CONTENT_SERVICE_V2) {
    urlsByVersion[2] = process.env.NEXT_PUBLIC_CONTENT_SERVICE_V2
  }

  return sharedMakeApolloClient({
    client: {
      name: 'website-client',
    },
    operationLogging: process.env.NODE_ENV === 'development',
    defaultVersion: 2,
    urlsByVersion,
    requestId,
    auth0,
    cookies: new Cookies(cookies),
    getSessionId,
    getAnonymousId,
  })
}
