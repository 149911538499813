import React, { useEffect } from 'react'
import { useAnalyticsSimplified as useAnalytics } from '@scentregroup/analytics'
import { useLocalUser } from '../hooks/use-local-user'

export const AnalyticsUserIdTracker: React.FC<
  React.PropsWithChildren<unknown>
> = () => {
  const analytics = useAnalytics()

  const user = useLocalUser()
  const userId = user?.scentregroupId || null

  useEffect(() => {
    analytics.setUserId(userId)
  }, [analytics, userId])

  return <></>
}
