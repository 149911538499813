import React, { useContext } from 'react'
import { Link as ChakraLink } from '@chakra-ui/react'
import { forwardRef, HubLinkContext } from '@hub/design-system-base'
import type { As, HubStyleObject } from '@hub/design-system-base'
import { Button } from '@hub/button'
import type { ButtonProps } from '@hub/button'
import { Box } from '@hub/box'
import { LinkOverlay } from './link-overlay'
import { LinkBox } from './link-box'

interface LinkProps {
  as?: As
  sx?: HubStyleObject
  href: string
  isExternal?: boolean
  hasUnderline?: boolean
  className?: string
  onClick?: React.MouseEventHandler
  ariaLabel?: string
  icon?: React.ReactElement
}

// TODO: Make work with Next links, external links, and internal non-Next links
export const Link = forwardRef<LinkProps, typeof ChakraLink>(
  (
    {
      children,
      href,
      isExternal,
      as,
      sx,
      hasUnderline = false,
      className,
      onClick,
      rel,
      target,
      id,
      ariaLabel,
      icon,
      ...rest
    },
    ref
  ) => {
    const Link = useContext(HubLinkContext)
    if (icon && !children) {
      return (
        <LinkBox
          sx={{
            minWidth: 'size-8',
            height: 'size-8',
            display: 'inline-flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <LinkOverlay
            ref={ref}
            href={href}
            rel={rel}
            target={target}
            as={
              as ||
              (Link &&
                forwardRef((props, ref) => (
                  <Link ref={ref} to={props.href} {...props} />
                )))
            }
            sx={sx}
            isExternal={isExternal}
            className={className}
            {...(hasUnderline ? { textDecoration: 'underline' } : {})}
            id={id}
            aria-label={ariaLabel}
            onClick={onClick}
          >
            {icon}
          </LinkOverlay>
        </LinkBox>
      )
    }
    if (icon && children) {
      return (
        <LinkBox
          sx={{
            minWidth: 'size-8',
            height: 'size-8',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'start',
            gap: 'spacing-sm',
          }}
        >
          {icon}
          <LinkOverlay
            ref={ref}
            href={href}
            rel={rel}
            target={target}
            as={
              as ||
              (Link &&
                forwardRef((props, ref) => (
                  <Link ref={ref} to={props.href} {...props} />
                )))
            }
            sx={sx}
            isExternal={isExternal}
            className={className}
            {...(hasUnderline ? { textDecoration: 'underline' } : {})}
            id={id}
            aria-label={ariaLabel}
            onClick={onClick}
          >
            {children}
          </LinkOverlay>
        </LinkBox>
      )
    }
    return (
      <ChakraLink
        ref={ref}
        href={href}
        rel={rel}
        target={target}
        as={
          as ||
          (Link &&
            forwardRef((props, ref) => (
              <Link ref={ref} to={props.href} onClick={onClick} {...props} />
            )))
        }
        sx={sx}
        isExternal={isExternal}
        className={className}
        {...(as ? { onClick } : {})}
        {...(hasUnderline ? { textDecoration: 'underline' } : {})}
        id={id}
        {...rest}
        aria-label={ariaLabel}
      >
        {children ?? href}
      </ChakraLink>
    )
  }
)

export const LinkButtonOverlay: React.FC<
  React.PropsWithChildren<LinkProps & Omit<ButtonProps, 'onClick'>>
> = props => (
  // Very similar to <LinkButton>, but we manually combine the classNames which
  // is safe to do because `<LinkOverlay>` is unstyled by default in Chakra.
  <LinkOverlay
    {...props}
    as={forwardRef((linkProps, overlayRef) => (
      <Button
        {...props}
        {...linkProps}
        as={forwardRef((buttonProps, _buttonRef) => (
          <Box
            as={forwardRef((props, _boxRef) => (
              <Link to={props.href} ref={overlayRef} {...props} />
            ))}
            {...buttonProps}
            {...linkProps}
            className={`${buttonProps.className} ${linkProps.className}`}
          />
        ))}
      />
    ))}
  />
)

export { LinkBox } from './link-box'
export { LinkOverlay }
