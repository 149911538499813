export const foundations = {
  color: {
    palette: {
      backgroundPrimary: {
        value: "#F5F6F7",
        comment: "",
        compliant: "",
        filePath: "src/color/palette.json",
        isSource: true,
        original: {
          value: "#F5F6F7",
          comment: "",
          compliant: "",
        },
        name: "backgroundPrimary",
        attributes: {
          category: "color",
          type: "palette",
          item: "backgroundPrimary",
        },
        path: ["color", "palette", "backgroundPrimary"],
      },
      backgroundSecondary: {
        value: "#FFEBF0",
        comment: "",
        compliant: "",
        filePath: "src/color/palette.json",
        isSource: true,
        original: {
          value: "#FFEBF0",
          comment: "",
          compliant: "",
        },
        name: "backgroundSecondary",
        attributes: {
          category: "color",
          type: "palette",
          item: "backgroundSecondary",
        },
        path: ["color", "palette", "backgroundSecondary"],
      },
      backgroundTertiary: {
        value: "#FFFFFF",
        comment: "",
        compliant: "",
        filePath: "src/color/palette.json",
        isSource: true,
        original: {
          value: "#FFFFFF",
          comment: "",
          compliant: "",
        },
        name: "backgroundTertiary",
        attributes: {
          category: "color",
          type: "palette",
          item: "backgroundTertiary",
        },
        path: ["color", "palette", "backgroundTertiary"],
      },
      borderError: {
        value: "#C52D2F",
        comment: "",
        compliant: "",
        filePath: "src/color/palette.json",
        isSource: true,
        original: {
          value: "#C52D2F",
          comment: "",
          compliant: "",
        },
        name: "borderError",
        attributes: {
          category: "color",
          type: "palette",
          item: "borderError",
        },
        path: ["color", "palette", "borderError"],
      },
      borderInfo: {
        value: "#1663AC",
        comment: "",
        compliant: "",
        filePath: "src/color/palette.json",
        isSource: true,
        original: {
          value: "#1663AC",
          comment: "",
          compliant: "",
        },
        name: "borderInfo",
        attributes: {
          category: "color",
          type: "palette",
          item: "borderInfo",
        },
        path: ["color", "palette", "borderInfo"],
      },
      borderMembershipPrimary: {
        value: "#DCB5C4",
        comment: "",
        compliant: "",
        filePath: "src/color/palette.json",
        isSource: true,
        original: {
          value: "#DCB5C4",
          comment: "",
          compliant: "",
        },
        name: "borderMembershipPrimary",
        attributes: {
          category: "color",
          type: "palette",
          item: "borderMembershipPrimary",
        },
        path: ["color", "palette", "borderMembershipPrimary"],
      },
      borderMembershipSecondary: {
        value: "#9084B7",
        comment: "",
        compliant: "",
        filePath: "src/color/palette.json",
        isSource: true,
        original: {
          value: "#9084B7",
          comment: "",
          compliant: "",
        },
        name: "borderMembershipSecondary",
        attributes: {
          category: "color",
          type: "palette",
          item: "borderMembershipSecondary",
        },
        path: ["color", "palette", "borderMembershipSecondary"],
      },
      borderMembershipTertiary: {
        value: "#4E8F98",
        comment: "",
        compliant: "",
        filePath: "src/color/palette.json",
        isSource: true,
        original: {
          value: "#4E8F98",
          comment: "",
          compliant: "",
        },
        name: "borderMembershipTertiary",
        attributes: {
          category: "color",
          type: "palette",
          item: "borderMembershipTertiary",
        },
        path: ["color", "palette", "borderMembershipTertiary"],
      },
      borderPrimary: {
        value: "#1C1F24",
        comment: "",
        compliant: "",
        filePath: "src/color/palette.json",
        isSource: true,
        original: {
          value: "#1C1F24",
          comment: "",
          compliant: "",
        },
        name: "borderPrimary",
        attributes: {
          category: "color",
          type: "palette",
          item: "borderPrimary",
        },
        path: ["color", "palette", "borderPrimary"],
      },
      borderSecondary: {
        value: "#A2A2A9",
        comment: "",
        compliant: "",
        filePath: "src/color/palette.json",
        isSource: true,
        original: {
          value: "#A2A2A9",
          comment: "",
          compliant: "",
        },
        name: "borderSecondary",
        attributes: {
          category: "color",
          type: "palette",
          item: "borderSecondary",
        },
        path: ["color", "palette", "borderSecondary"],
      },
      borderSuccess: {
        value: "#51722C",
        comment: "",
        compliant: "",
        filePath: "src/color/palette.json",
        isSource: true,
        original: {
          value: "#51722C",
          comment: "",
          compliant: "",
        },
        name: "borderSuccess",
        attributes: {
          category: "color",
          type: "palette",
          item: "borderSuccess",
        },
        path: ["color", "palette", "borderSuccess"],
      },
      borderTertiary: {
        value: "#D9DADD",
        comment: "",
        compliant: "",
        filePath: "src/color/palette.json",
        isSource: true,
        original: {
          value: "#D9DADD",
          comment: "",
          compliant: "",
        },
        name: "borderTertiary",
        attributes: {
          category: "color",
          type: "palette",
          item: "borderTertiary",
        },
        path: ["color", "palette", "borderTertiary"],
      },
      borderWarning: {
        value: "#95570D",
        comment: "",
        compliant: "",
        filePath: "src/color/palette.json",
        isSource: true,
        original: {
          value: "#95570D",
          comment: "",
          compliant: "",
        },
        name: "borderWarning",
        attributes: {
          category: "color",
          type: "palette",
          item: "borderWarning",
        },
        path: ["color", "palette", "borderWarning"],
      },
      brandPrimary: {
        value: "#F22D45",
        comment: "",
        compliant: "",
        filePath: "src/color/palette.json",
        isSource: true,
        original: {
          value: "#F22D45",
          comment: "",
          compliant: "",
        },
        name: "brandPrimary",
        attributes: {
          category: "color",
          type: "palette",
          item: "brandPrimary",
        },
        path: ["color", "palette", "brandPrimary"],
      },
      iconError: {
        value: "#C52D2F",
        comment: "",
        compliant: "",
        filePath: "src/color/palette.json",
        isSource: true,
        original: {
          value: "#C52D2F",
          comment: "",
          compliant: "",
        },
        name: "iconError",
        attributes: {
          category: "color",
          type: "palette",
          item: "iconError",
        },
        path: ["color", "palette", "iconError"],
      },
      iconPrimary: {
        value: "#1C1F24",
        comment: "",
        compliant: "",
        filePath: "src/color/palette.json",
        isSource: true,
        original: {
          value: "#1C1F24",
          comment: "",
          compliant: "",
        },
        name: "iconPrimary",
        attributes: {
          category: "color",
          type: "palette",
          item: "iconPrimary",
        },
        path: ["color", "palette", "iconPrimary"],
      },
      iconBrandPrimary: {
        value: "#F22D45",
        comment: "",
        compliant: "",
        filePath: "src/color/palette.json",
        isSource: true,
        original: {
          value: "#F22D45",
          comment: "",
          compliant: "",
        },
        name: "iconBrandPrimary",
        attributes: {
          category: "color",
          type: "palette",
          item: "iconBrandPrimary",
        },
        path: ["color", "palette", "iconBrandPrimary"],
      },
      iconSecondary: {
        value: "#686870",
        comment: "",
        compliant: "",
        filePath: "src/color/palette.json",
        isSource: true,
        original: {
          value: "#686870",
          comment: "",
          compliant: "",
        },
        name: "iconSecondary",
        attributes: {
          category: "color",
          type: "palette",
          item: "iconSecondary",
        },
        path: ["color", "palette", "iconSecondary"],
      },
      iconSuccess: {
        value: "#51722C",
        comment: "",
        compliant: "",
        filePath: "src/color/palette.json",
        isSource: true,
        original: {
          value: "#51722C",
          comment: "",
          compliant: "",
        },
        name: "iconSuccess",
        attributes: {
          category: "color",
          type: "palette",
          item: "iconSuccess",
        },
        path: ["color", "palette", "iconSuccess"],
      },
      iconSurfacePrimary: {
        value: "#FFFFFF",
        comment: "",
        compliant: "",
        filePath: "src/color/palette.json",
        isSource: true,
        original: {
          value: "#FFFFFF",
          comment: "",
          compliant: "",
        },
        name: "iconSurfacePrimary",
        attributes: {
          category: "color",
          type: "palette",
          item: "iconSurfacePrimary",
        },
        path: ["color", "palette", "iconSurfacePrimary"],
      },
      iconTertiary: {
        value: "#A2A2A9",
        comment: "",
        compliant: "",
        filePath: "src/color/palette.json",
        isSource: true,
        original: {
          value: "#A2A2A9",
          comment: "",
          compliant: "",
        },
        name: "iconTertiary",
        attributes: {
          category: "color",
          type: "palette",
          item: "iconTertiary",
        },
        path: ["color", "palette", "iconTertiary"],
      },
      iconWarning: {
        value: "#95570D",
        comment: "",
        compliant: "",
        filePath: "src/color/palette.json",
        isSource: true,
        original: {
          value: "#95570D",
          comment: "",
          compliant: "",
        },
        name: "iconWarning",
        attributes: {
          category: "color",
          type: "palette",
          item: "iconWarning",
        },
        path: ["color", "palette", "iconWarning"],
      },
      iconInfo: {
        value: "#1663AC",
        comment: "",
        compliant: "",
        filePath: "src/color/palette.json",
        isSource: true,
        original: {
          value: "#1663AC",
          comment: "",
          compliant: "",
        },
        name: "iconInfo",
        attributes: {
          category: "color",
          type: "palette",
          item: "iconInfo",
        },
        path: ["color", "palette", "iconInfo"],
      },
      shapeBrandAccent01Dark: {
        value: "#F22D45",
        comment: "",
        compliant: "",
        filePath: "src/color/palette.json",
        isSource: true,
        original: {
          value: "#F22D45",
          comment: "",
          compliant: "",
        },
        name: "shapeBrandAccent01Dark",
        attributes: {
          category: "color",
          type: "palette",
          item: "shapeBrandAccent01Dark",
        },
        path: ["color", "palette", "shapeBrandAccent01Dark"],
      },
      shapeBrandAccent01Light: {
        value: "#FB7DAC",
        comment: "",
        compliant: "",
        filePath: "src/color/palette.json",
        isSource: true,
        original: {
          value: "#FB7DAC",
          comment: "",
          compliant: "",
        },
        name: "shapeBrandAccent01Light",
        attributes: {
          category: "color",
          type: "palette",
          item: "shapeBrandAccent01Light",
        },
        path: ["color", "palette", "shapeBrandAccent01Light"],
      },
      shapeBrandAccent02Dark: {
        value: "#493E71",
        comment: "",
        compliant: "",
        filePath: "src/color/palette.json",
        isSource: true,
        original: {
          value: "#493E71",
          comment: "",
          compliant: "",
        },
        name: "shapeBrandAccent02Dark",
        attributes: {
          category: "color",
          type: "palette",
          item: "shapeBrandAccent02Dark",
        },
        path: ["color", "palette", "shapeBrandAccent02Dark"],
      },
      shapeBrandAccent02Light: {
        value: "#D8CBFE",
        comment: "",
        compliant: "",
        filePath: "src/color/palette.json",
        isSource: true,
        original: {
          value: "#D8CBFE",
          comment: "",
          compliant: "",
        },
        name: "shapeBrandAccent02Light",
        attributes: {
          category: "color",
          type: "palette",
          item: "shapeBrandAccent02Light",
        },
        path: ["color", "palette", "shapeBrandAccent02Light"],
      },
      shapeBrandAccent03Dark: {
        value: "#0A6B74",
        comment: "",
        compliant: "",
        filePath: "src/color/palette.json",
        isSource: true,
        original: {
          value: "#0A6B74",
          comment: "",
          compliant: "",
        },
        name: "shapeBrandAccent03Dark",
        attributes: {
          category: "color",
          type: "palette",
          item: "shapeBrandAccent03Dark",
        },
        path: ["color", "palette", "shapeBrandAccent03Dark"],
      },
      shapeBrandAccent03Light: {
        value: "#4E8F98",
        comment: "",
        compliant: "",
        filePath: "src/color/palette.json",
        isSource: true,
        original: {
          value: "#4E8F98",
          comment: "",
          compliant: "",
        },
        name: "shapeBrandAccent03Light",
        attributes: {
          category: "color",
          type: "palette",
          item: "shapeBrandAccent03Light",
        },
        path: ["color", "palette", "shapeBrandAccent03Light"],
      },
      surfaceError: {
        value: "#FEE1E1",
        comment: "",
        compliant: "",
        filePath: "src/color/palette.json",
        isSource: true,
        original: {
          value: "#FEE1E1",
          comment: "",
          compliant: "",
        },
        name: "surfaceError",
        attributes: {
          category: "color",
          type: "palette",
          item: "surfaceError",
        },
        path: ["color", "palette", "surfaceError"],
      },
      surfaceInfo: {
        value: "#BEE3F8",
        comment: "",
        compliant: "",
        filePath: "src/color/palette.json",
        isSource: true,
        original: {
          value: "#BEE3F8",
          comment: "",
          compliant: "",
        },
        name: "surfaceInfo",
        attributes: {
          category: "color",
          type: "palette",
          item: "surfaceInfo",
        },
        path: ["color", "palette", "surfaceInfo"],
      },
      surfaceMembershipPrimary: {
        value: "#FFEBF0",
        comment: "",
        compliant: "",
        filePath: "src/color/palette.json",
        isSource: true,
        original: {
          value: "#FFEBF0",
          comment: "",
          compliant: "",
        },
        name: "surfaceMembershipPrimary",
        attributes: {
          category: "color",
          type: "palette",
          item: "surfaceMembershipPrimary",
        },
        path: ["color", "palette", "surfaceMembershipPrimary"],
      },
      surfaceMembershipSecondary: {
        value: "#D8CBFE",
        comment: "",
        compliant: "",
        filePath: "src/color/palette.json",
        isSource: true,
        original: {
          value: "#D8CBFE",
          comment: "",
          compliant: "",
        },
        name: "surfaceMembershipSecondary",
        attributes: {
          category: "color",
          type: "palette",
          item: "surfaceMembershipSecondary",
        },
        path: ["color", "palette", "surfaceMembershipSecondary"],
      },
      surfaceMembershipTertiary: {
        value: "#D0EEEC",
        comment: "",
        compliant: "",
        filePath: "src/color/palette.json",
        isSource: true,
        original: {
          value: "#D0EEEC",
          comment: "",
          compliant: "",
        },
        name: "surfaceMembershipTertiary",
        attributes: {
          category: "color",
          type: "palette",
          item: "surfaceMembershipTertiary",
        },
        path: ["color", "palette", "surfaceMembershipTertiary"],
      },
      surfacePrimary: {
        value: "#1C1F24",
        comment: "",
        compliant: "",
        filePath: "src/color/palette.json",
        isSource: true,
        original: {
          value: "#1C1F24",
          comment: "",
          compliant: "",
        },
        name: "surfacePrimary",
        attributes: {
          category: "color",
          type: "palette",
          item: "surfacePrimary",
        },
        path: ["color", "palette", "surfacePrimary"],
      },
      surfaceSecondary: {
        value: "#D9DADD",
        comment: "",
        compliant: "",
        filePath: "src/color/palette.json",
        isSource: true,
        original: {
          value: "#D9DADD",
          comment: "",
          compliant: "",
        },
        name: "surfaceSecondary",
        attributes: {
          category: "color",
          type: "palette",
          item: "surfaceSecondary",
        },
        path: ["color", "palette", "surfaceSecondary"],
      },
      surfaceSuccess: {
        value: "#DEEDD3",
        comment: "",
        compliant: "",
        filePath: "src/color/palette.json",
        isSource: true,
        original: {
          value: "#DEEDD3",
          comment: "",
          compliant: "",
        },
        name: "surfaceSuccess",
        attributes: {
          category: "color",
          type: "palette",
          item: "surfaceSuccess",
        },
        path: ["color", "palette", "surfaceSuccess"],
      },
      surfaceTertiary: {
        value: "#FFFFFF",
        comment: "",
        compliant: "",
        filePath: "src/color/palette.json",
        isSource: true,
        original: {
          value: "#FFFFFF",
          comment: "",
          compliant: "",
        },
        name: "surfaceTertiary",
        attributes: {
          category: "color",
          type: "palette",
          item: "surfaceTertiary",
        },
        path: ["color", "palette", "surfaceTertiary"],
      },
      surfaceWarning: {
        value: "#FAE6B3",
        comment: "",
        compliant: "",
        filePath: "src/color/palette.json",
        isSource: true,
        original: {
          value: "#FAE6B3",
          comment: "",
          compliant: "",
        },
        name: "surfaceWarning",
        attributes: {
          category: "color",
          type: "palette",
          item: "surfaceWarning",
        },
        path: ["color", "palette", "surfaceWarning"],
      },
      textBrandPrimary: {
        value: "#F22D45",
        comment: "",
        compliant: "",
        filePath: "src/color/palette.json",
        isSource: true,
        original: {
          value: "#F22D45",
          comment: "",
          compliant: "",
        },
        name: "textBrandPrimary",
        attributes: {
          category: "color",
          type: "palette",
          item: "textBrandPrimary",
        },
        path: ["color", "palette", "textBrandPrimary"],
      },
      textError: {
        value: "#C52D2F",
        comment: "",
        compliant: "",
        filePath: "src/color/palette.json",
        isSource: true,
        original: {
          value: "#C52D2F",
          comment: "",
          compliant: "",
        },
        name: "textError",
        attributes: {
          category: "color",
          type: "palette",
          item: "textError",
        },
        path: ["color", "palette", "textError"],
      },
      textInfo: {
        value: "#1663AC",
        comment: "",
        compliant: "",
        filePath: "src/color/palette.json",
        isSource: true,
        original: {
          value: "#1663AC",
          comment: "",
          compliant: "",
        },
        name: "textInfo",
        attributes: {
          category: "color",
          type: "palette",
          item: "textInfo",
        },
        path: ["color", "palette", "textInfo"],
      },
      textPrimary: {
        value: "#1C1F24",
        comment: "",
        compliant: "",
        filePath: "src/color/palette.json",
        isSource: true,
        original: {
          value: "#1C1F24",
          comment: "",
          compliant: "",
        },
        name: "textPrimary",
        attributes: {
          category: "color",
          type: "palette",
          item: "textPrimary",
        },
        path: ["color", "palette", "textPrimary"],
      },
      textSecondary: {
        value: "#686870",
        comment: "",
        compliant: "",
        filePath: "src/color/palette.json",
        isSource: true,
        original: {
          value: "#686870",
          comment: "",
          compliant: "",
        },
        name: "textSecondary",
        attributes: {
          category: "color",
          type: "palette",
          item: "textSecondary",
        },
        path: ["color", "palette", "textSecondary"],
      },
      textSuccess: {
        value: "#51722C",
        comment: "",
        compliant: "",
        filePath: "src/color/palette.json",
        isSource: true,
        original: {
          value: "#51722C",
          comment: "",
          compliant: "",
        },
        name: "textSuccess",
        attributes: {
          category: "color",
          type: "palette",
          item: "textSuccess",
        },
        path: ["color", "palette", "textSuccess"],
      },
      textSurfacePrimary: {
        value: "#FFFFFF",
        comment: "",
        compliant: "",
        filePath: "src/color/palette.json",
        isSource: true,
        original: {
          value: "#FFFFFF",
          comment: "",
          compliant: "",
        },
        name: "textSurfacePrimary",
        attributes: {
          category: "color",
          type: "palette",
          item: "textSurfacePrimary",
        },
        path: ["color", "palette", "textSurfacePrimary"],
      },
      textWarning: {
        value: "#95570D",
        comment: "",
        compliant: "",
        filePath: "src/color/palette.json",
        isSource: true,
        original: {
          value: "#95570D",
          comment: "",
          compliant: "",
        },
        name: "textWarning",
        attributes: {
          category: "color",
          type: "palette",
          item: "textWarning",
        },
        path: ["color", "palette", "textWarning"],
      },
      wayfindingPath: {
        value: "#1663AC",
        comment: "",
        compliant: "",
        filePath: "src/color/palette.json",
        isSource: true,
        original: {
          value: "#1663AC",
          comment: "",
          compliant: "",
        },
        name: "wayfindingPath",
        attributes: {
          category: "color",
          type: "palette",
          item: "wayfindingPath",
        },
        path: ["color", "palette", "wayfindingPath"],
      },
      wayfindingPathCovered: {
        value: "#686870",
        comment: "",
        compliant: "",
        filePath: "src/color/palette.json",
        isSource: true,
        original: {
          value: "#686870",
          comment: "",
          compliant: "",
        },
        name: "wayfindingPathCovered",
        attributes: {
          category: "color",
          type: "palette",
          item: "wayfindingPathCovered",
        },
        path: ["color", "palette", "wayfindingPathCovered"],
      },
      wayfindingWaypoint: {
        value: "#1663AC",
        comment: "",
        compliant: "",
        filePath: "src/color/palette.json",
        isSource: true,
        original: {
          value: "#1663AC",
          comment: "",
          compliant: "",
        },
        name: "wayfindingWaypoint",
        attributes: {
          category: "color",
          type: "palette",
          item: "wayfindingWaypoint",
        },
        path: ["color", "palette", "wayfindingWaypoint"],
      },
      wayfindingWaypointBorder: {
        value: "#FFFFFF",
        comment: "",
        compliant: "",
        filePath: "src/color/palette.json",
        isSource: true,
        original: {
          value: "#FFFFFF",
          comment: "",
          compliant: "",
        },
        name: "wayfindingWaypointBorder",
        attributes: {
          category: "color",
          type: "palette",
          item: "wayfindingWaypointBorder",
        },
        path: ["color", "palette", "wayfindingWaypointBorder"],
      },
      surfaceBrandPrimary: {
        value: "#FFF6ED",
        comment: "Cream",
        compliant: "",
        filePath: "src/color/palette.json",
        isSource: true,
        original: {
          value: "#FFF6ED",
          comment: "Cream",
          compliant: "",
        },
        name: "surfaceBrandPrimary",
        attributes: {
          category: "color",
          type: "palette",
          item: "surfaceBrandPrimary",
        },
        path: ["color", "palette", "surfaceBrandPrimary"],
      },
      surfaceBrandSecondary: {
        value: "#FAE3D0",
        comment: "Linen",
        compliant: "",
        filePath: "src/color/palette.json",
        isSource: true,
        original: {
          value: "#FAE3D0",
          comment: "Linen",
          compliant: "",
        },
        name: "surfaceBrandSecondary",
        attributes: {
          category: "color",
          type: "palette",
          item: "surfaceBrandSecondary",
        },
        path: ["color", "palette", "surfaceBrandSecondary"],
      },
      surfacePrimaryHover: {
        value: "#686870",
        comment: "Quartz",
        compliant: "",
        filePath: "src/color/palette.json",
        isSource: true,
        original: {
          value: "#686870",
          comment: "Quartz",
          compliant: "",
        },
        name: "surfacePrimaryHover",
        attributes: {
          category: "color",
          type: "palette",
          item: "surfacePrimaryHover",
        },
        path: ["color", "palette", "surfacePrimaryHover"],
      },
      shapeBrandPrimary: {
        value: "#DA291C",
        comment: "Westfield Red",
        compliant: "",
        filePath: "src/color/palette.json",
        isSource: true,
        original: {
          value: "#DA291C",
          comment: "Westfield Red",
          compliant: "",
        },
        name: "shapeBrandPrimary",
        attributes: {
          category: "color",
          type: "palette",
          item: "shapeBrandPrimary",
        },
        path: ["color", "palette", "shapeBrandPrimary"],
      },
      shapeBrandSecondary: {
        value: "#FFF6ED",
        comment: "Cream",
        compliant: "",
        filePath: "src/color/palette.json",
        isSource: true,
        original: {
          value: "#FFF6ED",
          comment: "Cream",
          compliant: "",
        },
        name: "shapeBrandSecondary",
        attributes: {
          category: "color",
          type: "palette",
          item: "shapeBrandSecondary",
        },
        path: ["color", "palette", "shapeBrandSecondary"],
      },
      storeSelectedFill: {
        value: "#F7E4BB",
        comment: "",
        compliant: "",
        filePath: "src/color/palette.json",
        isSource: true,
        original: {
          value: "#F7E4BB",
          comment: "",
          compliant: "",
        },
        name: "storeSelectedFill",
        attributes: {
          category: "color",
          type: "palette",
          item: "storeSelectedFill",
        },
        path: ["color", "palette", "storeSelectedFill"],
      },
      storeSelectedBorder: {
        value: "#C6B591",
        comment: "",
        compliant: "",
        filePath: "src/color/palette.json",
        isSource: true,
        original: {
          value: "#C6B591",
          comment: "",
          compliant: "",
        },
        name: "storeSelectedBorder",
        attributes: {
          category: "color",
          type: "palette",
          item: "storeSelectedBorder",
        },
        path: ["color", "palette", "storeSelectedBorder"],
      },
    },
  },
}
export default foundations
