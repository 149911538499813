import { jwtDecode, JwtPayload } from 'jwt-decode'

const DEFAULT_LEEWAY = 60

export function decodeJWT<TJwtPayload = JwtPayload>(jwt: string): TJwtPayload {
  return jwtDecode<TJwtPayload>(jwt)
}

// We don't verify the token, we just decode and check certain fields
export const isInsecurelyValidJWT = (
  jwt: string,
  options: { leeway: number } = { leeway: DEFAULT_LEEWAY }
): boolean => {
  try {
    return isInsecurelyValidJWTPayload(decodeJWT(jwt), options)
  } catch {
    return false
  }
}

export function isInsecurelyValidJWTPayload(
  payload: JwtPayload,
  options: { leeway: number } = { leeway: DEFAULT_LEEWAY }
): boolean {
  const { leeway } = options
  const now = new Date()

  if (hasExpired(payload, leeway, now) || hasNotBegun(payload, leeway, now)) {
    return false
  } else {
    return true
  }
}

export const hasExpired = (
  payload: JwtPayload,
  leeway: number,
  now: Date
): boolean => {
  const expDate = new Date(0)
  if (payload.exp) {
    expDate.setUTCSeconds(payload.exp + leeway)
    return now > expDate
  } else {
    // We don't accept tokens without an Expiry time
    return true
  }
}

export const hasNotBegun = (
  payload: JwtPayload,
  leeway: number,
  now: Date
): boolean => {
  const nbfDate = new Date(0)
  if (payload.nbf) {
    nbfDate.setUTCSeconds(payload.nbf - leeway)
    return now < nbfDate
  } else {
    // Nbf is optional
    return false
  }
}
