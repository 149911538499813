import React from 'react'
import {
  QueryParamAdapterComponent,
  QueryParamProvider,
} from 'use-query-params'

import { useRouter } from '@scentregroup/shared/next/router'

const NextQueryParamAdapter: QueryParamAdapterComponent = ({ children }) => {
  const router = useRouter()

  const updateRouter = async (
    method: 'replace' | 'push',
    { search }: { search: string }
  ): Promise<boolean> => {
    const newUrl = {
      query: search.slice(1),
      pathname: router.asPath.replace(/\?.*/, ''),
    }
    return await router[method](newUrl, newUrl, { shallow: true })
  }

  return children({
    get location() {
      return {
        search: router.asPath.replace(/[^?]+/u, ''),
      }
    },
    push(location) {
      return updateRouter('push', location)
    },
    replace(location) {
      return updateRouter('replace', location)
    },
  })
}

const NextQueryParamsProvider: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  return (
    <QueryParamProvider adapter={NextQueryParamAdapter}>
      {children}
    </QueryParamProvider>
  )
}

export default NextQueryParamsProvider
