import { HttpLink } from '@apollo/client'
import { abortableFetch } from 'abortcontroller-polyfill/dist/cjs-ponyfill'

interface HttpLinkOptions {
  defaultVersion: number
  urlsByVersion: Record<number, string>
}

export function makeHttpLink({
  defaultVersion,
  urlsByVersion,
}: HttpLinkOptions): HttpLink {
  return new HttpLink({
    fetch: abortableFetch(fetch).fetch,
    uri(operation) {
      const version = operation.getContext().version || defaultVersion
      const url = urlsByVersion[version]
      if (!url) {
        throw new Error(`no url for version ${version}`)
      }
      return url
    },
  })
}
