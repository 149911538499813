export const getBrowser = staticContext => {
  if (staticContext && staticContext.agent) {
    return staticContext.agent
  } else if (typeof navigator !== 'undefined') {
    return navigator.userAgent
  }
  return ''
}

// This has limited device coverage - just iOS and Android - use sparingly
export const isMobile = agent => /iphone|ipad|ipod|android/i.test(agent)

export const isIPhone = agent => /iphone|ipod/i.test(agent)

export const agentInfo = agent => ({
  agent,
  isIPhone: isIPhone(agent),
  isMobile: isMobile(agent),
})
