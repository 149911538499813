import React from 'react'
import { Imposter } from '@hub/imposter'
import { Cover } from '@hub/cover'
import { useIsRouteChanging } from '../../next/router'

export interface IRouteChangingProps {
  fallback: React.ComponentType<React.PropsWithChildren<unknown>>
}

const RouteChangeIndicator: React.FunctionComponent<
  React.PropsWithChildren<IRouteChangingProps>
> = function RouteChangeIndicator({ fallback: Fallback, children }) {
  const isRouteChanging = useIsRouteChanging()
  return (
    <Imposter
      impose={
        isRouteChanging ? (
          <Cover sx={{ zIndex: 'popover' }} backgroundColor="white">
            <Fallback />
          </Cover>
        ) : undefined
      }
      sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}
    >
      {children}
    </Imposter>
  )
}

export default RouteChangeIndicator
